// import React, { useEffect, useRef } from "react";
// import { gsap, TextPlugin } from "gsap/all";
// import "./Home.css";

// const Navbar = () => {
//     const typewriterRef = useRef(null);

//     useEffect(() => {
//         // Register the TextPlugin
//         gsap.registerPlugin(TextPlugin);

//         // Ensure the typewriter animation runs on the target span
//         const text = "Book your tickets now!";
//         gsap.to(typewriterRef.current, {
//             text: text,
//             duration: 3, // Adjust typing speed
//             ease: "power1.inOut",
//             repeat: -1, // Infinite repeat
//             repeatDelay: 1, // Delay before repeat
//             yoyo: true, // Reverse animation
//         });
//     }, []);

//     return (
//         <>
//         <nav className="navbar navbar-expand-lg navbar-dark bg-black">
//             <div className="container">
//                 {/* Left Side Content */}
//                 <div className="navbar-content text-white">
//                     <span className="live-notice">
//                         LIVE in Hyderabad, Dec 6-8th. Tickets going out of stock soon.{" "}
//                         <strong>
//                             <a
//                                 href="#"
//                                 className="book-now"
//                                 style={{
//                                     textDecoration: "underline",
//                                     color: "#f0ad4e",
//                                 }}
//                             >
//                                 <span ref={typewriterRef}></span>
//                             </a>
//                         </strong>
//                     </span>
//                 </div>

//                 {/* Right Side Buttons */}
//                 <div className="d-flex gap-3">
//                     <button className="btn btn-outline-warning">Signup</button>
//                     <button className="btn btn-warning">Login</button>
//                 </div>
//             </div>
//         </nav>


//         </>
//     );
// };

// export default Navbar;



// import React, { useEffect, useRef } from "react";
// import { gsap, TextPlugin } from "gsap/all";
// import CreatorWerse from "../assets/CreatorWerse.png";
// import "./Home.css";


// const Home = () => {
//     const typewriterRef = useRef(null);
    

//     useEffect(() => {
//         // Register GSAP Plugins
//         gsap.registerPlugin(TextPlugin);

//         // Typewriter Effect for Navbar
//         const navbarText = "Book your tickets now!";
//         gsap.to(typewriterRef.current, {
//             text: navbarText,
//             duration: 3,
//             ease: "power1.inOut",
//             repeat: -1,
//             repeatDelay: 1,
//             yoyo: true,
//         });

//         gsap.fromTo(
//             ".hero-text h1",
//             { opacity: 0, y: 20 },
//             { opacity: 1, y: 0, duration: 1.5, stagger: 0.3 }
//         );
//     }, []);

//     return (
//         <div>
//             {/* Navbar */}
//             <nav className="navbar navbar-expand-lg navbar-dark bg-black">
//                 <div className="container">
//                     {/* Left Side Content */}
//                     <div className="navbar-content text-white">
//                         <span className="live-notice">
//                             LIVE in Hyderabad, Dec 6-8th. Tickets going out of stock soon.{" "}
//                             <strong>
//                                 <a
//                                     href="#"
//                                     className="book-now"
//                                     style={{
//                                         textDecoration: "underline",
//                                         color: "#f0ad4e",
//                                     }}
//                                 >
//                                     <span ref={typewriterRef}></span>
//                                 </a>
//                             </strong>
//                         </span>
//                     </div>

//                     {/* Right Side Buttons */}
//                     <div className="d-flex gap-3">
//                         <button className="btn btn-outline-warning">Signup</button>
//                         {/* <button className="btn btn-warning">Login</button> */}
//                     </div>
//                 </div>
//             </nav>

//             {/* Hero Section */}
//             <div className="hero-container">
//             {/* Hero Content */}
//             <div className="hero-content">
//                 {/* First Row: Logo */}
//                 <div className="hero-logo">
//                     <img
//                         src={CreatorWerse}
//                         alt="Logo"
//                         className="logo"
//                     />
//                 </div>

//                 {/* Second Row: Text */}
//                 <div className="hero-text">
//                     <h1>
//                         Everything <span className="highlight">YOU</span> Need
//                     </h1>
//                     <h1>
//                         To Make <span className="highlight">YOUR</span>
//                     </h1>
//                     <h1>
//                         First <span className="highlight underline">100 VIDEOS</span>
//                     </h1>
//                 </div>
//             </div>
//         </div>

//         </div>
//     );
// };

// export default Home;



import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { gsap, TextPlugin } from "gsap/all";
import CreatorWerse from "../assets/CreatorWerse.png";
import "./Home.css";
import EventLandingPage from './EventLandingPage';
import ContentSurvey from './ContentSurvey';
import TestimonialSection from './TestimonialSection';
import MembershipCard from './MembershipCard';
import LandingPage from './LandingPage';
import Footer from './Footer';
import Banner from './Banner';

const Home = () => {
    const navigate = useNavigate();
    const typewriterRef = useRef(null);

    useEffect(() => {
        // Register GSAP Plugins
        gsap.registerPlugin(TextPlugin);

        // Typewriter Effect for Navbar
        const navbarText = "Book your tickets now!";
        gsap.to(typewriterRef.current, {
            text: navbarText,
            duration: 3,
            ease: "power1.inOut",
            repeat: -1,
            repeatDelay: 1,
            yoyo: true,
        });

        gsap.fromTo(
            ".hero-text h1",
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: 1.5, stagger: 0.3 }
        );
    }, []);

    const handleSignup = () => {
        navigate('/signup');
    };
    const handleLogin = () => {
        navigate('/login');
    };
    return (
        <div>
            {/* Navbar */}
            <nav className="navbar navbar-expand-lg navbar-dark bg-black">
                <div className="container">
                    {/* Left Side Content */}
                    <div className="navbar-content text-white">
                        <span className="live-notice">
                            LIVE in Hyderabad, Dec 6-8th. Tickets going out of stock soon.{" "}
                            <strong>
                                <a
                                    href="#"
                                    className="book-now"
                                    style={{
                                        textDecoration: "underline",
                                        color: "#f0ad4e",
                                    }}
                                >
                                    <span ref={typewriterRef}></span>
                                </a>
                            </strong>
                        </span>
                    </div>

                    {/* Right Side Buttons */}
                    <div className="d-flex gap-4">
    {/* <button 
        className="btn btn-outline-warning btn-lg"
        onClick={handleSignup}
    >
        Signup
    </button> */}

<button 
    // className="btn btn-outline-warning btn-lg text-warning border-warning bg-transparent"
    className="btn btn-outline-warning btn-lg"
    onClick={handleSignup}
>
    Signup
</button>

    <button 
        // className="btn btn-warning btn-lg"


        //  className="btn btn-outline-warning btn-lg text-warning border-warning bg-transparent"
        className="btn btn-outline-warning btn-lg"
        onClick={handleLogin}
    >
        Login
    </button>
</div>
                </div>
            </nav>

            {/* Hero Section */}
            {/* <div className="hero-container vh-100">
               
                <div className="hero-content">
                    
                    <div className="hero-logo">
                        <img
                            src={CreatorWerse}
                            alt="Logo"
                            className="logo"
                        />
                    </div>

                   
                    <div className="hero-text">
                        <h1>
                            Everything <span className="highlight">YOU</span> Need
                        </h1>
                        <h1>
                            To Make <span className="highlight">YOUR</span>
                        </h1>
                        <h1>
                            First <span className="highlight underline">100 VIDEOS</span>
                        </h1>
                    </div>
                </div>
            </div> */}


<div className="hero-container vh-70 d-flex align-items-center justify-content-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 text-center">
                        <div className="hero-content">
                            <div className="hero-logo mb-4">
                                <img
                                    src={CreatorWerse}
                                    alt="Logo"
                                    className="img-fluid logo"
                                    style={{ maxWidth: '200px' }}
                                />
                            </div>
                            
                            <div className="hero-text">
                                <h1 className="display-4 fw-bold mb-3">
                                    Everything <span className="highlight">YOU</span> Need
                                </h1>
                                <h1 className="display-4 fw-bold mb-3">
                                    To Make <span className="highlight">YOUR</span>
                                </h1>
                                <h1 className="display-4 fw-bold">
                                    First <span className="highlight text-decoration-underline">100 VIDEOS</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div>
      {/* Main content */}
      <div className="main-content">
        <EventLandingPage />
        <ContentSurvey />
        <TestimonialSection />
        <MembershipCard />
        <LandingPage />
      </div>

      {/* Footer and Banner */}
      <Footer />
      <Banner />
    </div>
        </div>
    );
};

export default Home;