import React from 'react';
import { Award, Camera, Video, Star } from 'lucide-react';
import 'bootstrap/dist/css/bootstrap.min.css';

const MembershipCard = () => {
  const styles = {
    gradientText: {
      background: 'linear-gradient(45deg, #059669, #34d399)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    },
    membershipCard: {
      backgroundColor: '#000',
      borderRadius: '1rem',
      transition: 'transform 0.3s ease'
    },
    ctaButton: {
      backgroundColor: '#10b981',
      transition: 'all 0.3s ease'
    },
    iconFooter: {
      backgroundColor: '#064e3b'
    },
    emeraldIcon: {
      color: '#34d399'
    }
  };

  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          {/* Header Section */}
          <div className="text-center mb-4">
            <h1 className="display-5 fw-bold mb-3" style={styles.gradientText}>
              Create YOUR ON-CAMERA CHARISMA
            </h1>
            <p className="h3 fw-semibold text-dark">
              IGNITE YOUR Creative spirit FOR VIDEO CONTENT MASTERY
            </p>
          </div>

          {/* Card Section */}
          <div style={styles.membershipCard} className="shadow-lg">
            <div className="card border-0 bg-transparent">
              <div className="card-body p-5">
                {/* Card Header */}
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h2 className="h1 text-white mb-0 d-flex align-items-center">
                    <Award style={styles.emeraldIcon} className="me-2" />
                    Emerald Membership
                  </h2>
                </div>

                {/* Pricing Section */}
                <div className="mb-4">
                  <div className="text-secondary">
                    <span className="h5 text-decoration-line-through">
                      ORIGINAL PRICE: ₹3,00,000
                    </span>
                  </div>
                  <div className="h2 fw-bold text-danger mt-2">
                    GET TODAY: ₹1,00,000
                  </div>
                  <div className="text-secondary fst-italic">
                    (100-200 Videos / 2 Bootcamps each 4 days)
                  </div>
                </div>

                {/* CTA Button */}
                <a
                  href="https://razorpay.com/payment-link/plink_OnA47t0esXx9Bb"
                  className="btn btn-lg w-100 text-white fw-bold py-3 mb-4"
                  style={styles.ctaButton}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = '#34d399';
                    e.target.style.transform = 'translateY(-2px)';
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = '#10b981';
                    e.target.style.transform = 'translateY(0)';
                  }}
                >
                  Get Premium 1+1 Special Advantage Ticket Now
                </a>

                {/* Features */}
                <div className="text-center text-secondary">
                  <p className="mb-1">Premium Seating + Instagram mastery+</p>
                  <p className="mb-0">Youtube + Podcast challenge</p>
                </div>
              </div>

              {/* Icon Footer */}
              <div className="p-3 d-flex justify-content-center gap-4" style={styles.iconFooter}>
                <Video style={styles.emeraldIcon} size={24} />
                <Camera style={styles.emeraldIcon} size={24} />
                <Star style={styles.emeraldIcon} size={24} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipCard;