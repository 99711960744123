import React from 'react';
import { HelpCircle } from 'lucide-react';


const ContentSurvey = () => {


 
    const headerStyle = {
      // background: '#212529',
      padding: '2rem',
      textAlign: 'center',
      maxWidth: '70rem'
    };
  
    const subtitleStyle = {
      color: 'black',
      fontWeight: 'bold',
      letterSpacing: '1px'
    };
  
    const mainTitleStyle = {
      color: '#ffc107',
      fontWeight: 'bold',
      fontSize: '3.5rem',
      marginTop: '1rem'
    };
  
  const questions = [
    "Do you think you're working very very hard but still not getting the desired results?",
    "Do you feel you're stuck in your career & your work is not being noticed even after giving your 100%?",
    "Do you feel your Family Deserves better?",
    "Do you feel trapped in the current Business so much that you think you are not able to prioritize creating content?",
    "Is your past struggle with content creation and unsuccessful attempts impacting your present ability to prioritize it in your business?",
    "Are you projecting confidence about your business strategy while?",
    "Privately feeling overwhelmed and helpless about your inability to create content consistently?"
  ];

  return (
    <div>

    <div className="container my-5">
      <div className="card shadow-lg mx-auto" style={{ maxWidth: '70rem' }}>
        <div className="card-body p-4">
          <h1 className="display-5 fw-bold text-center mb-4">
            Are You <span className="text-warning text-decoration-underline">NOT</span> Able to Create Content?
          </h1>

          <div className="mb-5">
            {questions.map((question, index) => (
              <div key={index} className="d-flex align-items-start gap-3 mb-4">
                <HelpCircle className="flex-shrink-0 text-warning mt-1" style={{ width: '1.5rem', height: '1.5rem' }} />
                <p className="fs-4 mb-0">{question}</p>
              </div>
            ))}
          </div>

          <div className="bg-dark text-white p-4 rounded">
            <p className="fs-3 fw-bold text-center mb-3">
              THEN YOU <span className="text-warning">DON'T</span> NEED a Mountain Trip/Holiday/Hours Together of Browsing Social Media to Escape Your Reality,
            </p>
            <p className="fs-3 fw-bold text-center mb-0">
              YOU JUST NEED THIS CREATORWERSE LIVE EVENT <span className="text-warning">BADLY</span>...
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid" style={headerStyle}>
      <div className="row justify-content-center">
        <div className="col-12">
          <h2 className="h4 mb-3" style={subtitleStyle}>
            TRANSFORM YOUR CONTENT CREATION JOURNEY:
          </h2>
          <h1 style={mainTitleStyle}>
            DON'T ESCAPE, ELEVATE!
          </h1>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ContentSurvey;