// import React, { useState } from 'react';
// import { ChevronDown, ChevronUp } from 'lucide-react';
// import  EventCollage from '../assets/EventCollage.png'
 
// const Footer = () => {
//   const [expandedQuestion, setExpandedQuestion] = useState(null);

//   const questions = [
//     {
//       id: 1,
//       question: "When and Where is the Event happening?",
//       answer: "Event details will be shared upon registration."
//     },
//     {
//       id: 2,
//       question: "Who are the speakers in the Live Event?",
//       answer: "Our event features industry professionals and experienced creators who will guide you through your creative journey."
//     },
//     {
//       id: 3,
//       question: "How do I know if this is for me?",
//       answer: "This event is perfect for aspiring creators looking to overcome creative blocks and develop their authentic voice."
//     },
//     {
//       id: 4,
//       question: "Can I get a refund if I can't attend the Live Event?",
//       answer: "Please contact our support team for our refund policy details."
//     },
//     {
//       id: 5,
//       question: "Have more questions?",
//       answer: "Reach out to our support team for additional information."
//     }
//   ];

//   const toggleQuestion = (id) => {
//     setExpandedQuestion(expandedQuestion === id ? null : id);
//   };

//   return (
//     <div className="container-fluid bg-dark text-white py-5">
//       <div className="container">
//         <div className="row">
//           {/* Left side - Images */}
//           <div className="col-md-6 mb-4">
//             <img 
//               src={EventCollage}
//               alt="Creative Event"
//               className="img-fluid rounded shadow"
//             />
//           </div>

//           {/* Right side - Content */}
//           <div className="col-md-6">
//             {/* Inspirational Quote */}
//             <div className="mb-4">
//               <p className="fs-5 text-warning">
//                 Step through our doors and leave your doubts behind. This is a judgement-free zone 
//                 designed to liberate your imagination. Our inspirational backdrops transport you to any 
//                 setting, sparking endless storytelling possibilities. But we offer more than just sets – our 
//                 team of empathetic professionals guides you past mental blocks and self-limiting beliefs. 
//                 Together, we'll dismantle the barriers holding you back from sharing your authentic voice 
//                 with the world. Whether you crave confidence in front of the camera or need to rediscover 
//                 your artistic passion, our nurturing space will be your creative rebirth. You have a story to 
//                 tell – let us help you unleash it, boldly and beautifully.
//               </p>
//             </div>

//             <div className="mb-4">
//               <h1 className="display-5 fw-bold text-warning mb-3">
//                 Still Stuck To Take The Right Decision???
//               </h1>
//               <p className="fs-4 mb-4">
//                 Release All your doubts, I have your answers here...
//               </p>
//             </div>

//             {/* FAQ Accordion */}
//             <div className="accordion" id="faqAccordion">
//               {questions.map((item) => (
//                 <div key={item.id} className="accordion-item bg-dark border border-secondary">
//                   <h2 className="accordion-header">
//                     <button
//                       className={`accordion-button ${expandedQuestion === item.id ? '' : 'collapsed'} bg-dark text-white`}
//                       onClick={() => toggleQuestion(item.id)}
//                       style={{ 
//                         '--bs-accordion-btn-focus-box-shadow': 'none',
//                         '--bs-accordion-active-bg': '#212529'
//                       }}
//                     >
//                       <span className="fs-5">{item.question}</span>
//                       {expandedQuestion === item.id ? 
//                         <ChevronUp className="ms-auto" size={20} /> : 
//                         <ChevronDown className="ms-auto" size={20} />
//                       }
//                     </button>
//                   </h2>
//                   <div className={`accordion-collapse collapse ${expandedQuestion === item.id ? 'show' : ''}`}>
//                     <div className="accordion-body text-light">
//                       {item.answer}
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>

//             {/* CTA Button */}
//             <div className="mt-4 text-center">
//               <button className="btn btn-success btn-lg w-100 mb-2 py-3 fw-bold">
//                 Book Your Spot at The Creatorwerse Live Bootcamp Now!
//               </button>
//               <p className="text-secondary">
//                 Tickets going out of stock soon.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Footer;









import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

import  EventCollage from '../assets/EventCollage.png';
 
// Add these styles to your CSS file
const styles = `
.accordion-button:not(.collapsed) {
  background-color: #212529 !important;
  color: #fff !important;
}

.accordion-button::after {
  display: none;
}

.accordion-item {
  transition: all 0.3s ease;
}

.accordion-button {
  transition: all 0.3s ease !important;
}

.accordion-collapse {
  transition: all 0.5s ease !important;
}

.hover-grow {
  transition: transform 0.3s ease;
}

.hover-grow:hover {
  transform: scale(1.02);
}

.gradient-text {
  background: linear-gradient(45deg, #ffc107, #fd7e14);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  transition: all 0.3s ease;
  background: linear-gradient(45deg, #198754, #0d6efd);
  border: none;
}

.cta-button:hover {
  transform: scale(1.02);
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
}

.image-container::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #6f42c1, #dc3545);
  z-index: -1;
  border-radius: 0.6rem;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.image-container:hover::before {
  opacity: 0.8;
}

@keyframes pulse {
  0% { opacity: 1; }
  50% { opacity: 0.6; }
  100% { opacity: 1; }
}

.pulse-animation {
  animation: pulse 2s infinite;
}
`;

const Footer = () => {
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  const questions = [
    {
      id: 1,
      question: "When and Where is the Event happening?",
      answer: "The creatorwerse video Bootcamp is happening in Hyderabad on December 6,7,8  2024.   Location:Road Number 36 Jubilee Hills Time:9AM-4PM"
    },
    {
      id: 2,
      question: "Who are the speakers in the Live Event?",
      answer: "Dr. Mani Pavitra, and the Top Industry Experts with decades of experience will be present to give the transformation you have been longing for."
    },
    {
      id: 3,
      question: "How do I know if this is for me?",
      answer: "This Event is for people from all walks of life: Entrepreneurs, Doctors, Professionals, Executives, Creatives, Students….The list goes on. Everyone who’s struggling with personal growth & finances can get transformative insights and a renewed sense of purpose and direction in their life."
    },
    {
      id: 4,
      question: "Can I get a refund if I can't attend the Live Event?",
      answer: "We are not offering refunds . All ticket sales are final so that we can have an accurate headcount. However Event ticket can be Transferred to new date or another person."
    },
    {
      id: 5,
      question: "Have more questions?",
      answer: "Write us to at manipavitra82@gmail.com or Contact 7075016660 & we will clear all your doubts."
    }
  ];

  const toggleQuestion = (id) => {
    setExpandedQuestion(expandedQuestion === id ? null : id);
  };

  return (
    <>
      <style>{styles}</style>
      <div className="container-fluid bg-dark text-white py-5">
        <div className="container">
          <div className="row">
            {/* Left side - Images */}
            <div className="col-md-6 mb-4">
              <div className="image-container">
                <img 
                  src={EventCollage}
                  alt="Creative Event"
                  className="img-fluid hover-grow shadow"
                />
              </div>
            </div>

            {/* Right side - Content */}
            <div className="col-md-6">
              {/* Inspirational Quote */}
              <div className="mb-4 p-4 bg-black bg-opacity-50 rounded">
                <p className="fs-5 text-warning">
                  Step through our doors and leave your doubts behind. This is a judgement-free zone 
                  designed to liberate your imagination. Our inspirational backdrops transport you to any 
                  setting, sparking endless storytelling possibilities. But we offer more than just sets – our 
                  team of empathetic professionals guides you past mental blocks and self-limiting beliefs. 
                  Together, we'll dismantle the barriers holding you back from sharing your authentic voice 
                  with the world.
                </p>
              </div>

              <div className="mb-4">
                <h1 className="display-5 fw-bold gradient-text mb-3">
                  Still Stuck To Take The Right Decision?
                </h1>
                <p className="fs-4 mb-4 text-light">
                  Release All your doubts, I have your answers here...
                </p>
              </div>

              {/* FAQ Accordion */}
              <div className="accordion" id="faqAccordion">
                {questions.map((item) => (
                  <div key={item.id} className="accordion-item bg-dark border border-secondary mb-3">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${expandedQuestion === item.id ? '' : 'collapsed'} bg-dark text-white`}
                        onClick={() => toggleQuestion(item.id)}
                      >
                        <span className="fs-5">{item.question}</span>
                        {expandedQuestion === item.id ? 
                          <ChevronUp className="ms-auto" size={20} /> : 
                          <ChevronDown className="ms-auto" size={20} />
                        }
                      </button>
                    </h2>
                    <div className={`accordion-collapse collapse ${expandedQuestion === item.id ? 'show' : ''}`}>
                      <div className="accordion-body text-light">
                        {item.answer}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* CTA Button */}
              <div className="mt-5 text-center">
                <button className="btn btn-lg w-100 mb-2 py-3 fw-bold cta-button text-white">
                  Book Your Spot at The Creatorwerse Live Bootcamp Now!
                </button>
                <p className="text-warning pulse-animation">
                  🔥 Tickets going out of stock soon! 🔥
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;