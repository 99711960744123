
// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import CreatorWerse from "../../assets/CreatorWerse.png"

// const Login = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     emailOrMobile: '',
//     userId: '',
//     otp: ['', '', '', '', '', '']
//   });
//   const [errors, setErrors] = useState({});
//   const [showOTP, setShowOTP] = useState(false);

//   // Validate email or mobile number
//   const validateEmailOrMobile = (value) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const mobileRegex = /^[0-9]{10}$/;
//     return emailRegex.test(value) || mobileRegex.test(value);
//   };

//   // Handle input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prev => ({
//       ...prev,
//       [name]: value
//     }));
//     setErrors(prev => ({
//       ...prev,
//       [name]: ''
//     }));
//   };

//   // Handle OTP input
//   const handleOTPChange = (index, value) => {
//     if (value.length <= 1 && /^\d*$/.test(value)) {
//       const newOTP = [...formData.otp];
//       newOTP[index] = value;
//       setFormData(prev => ({
//         ...prev,
//         otp: newOTP
//       }));

//       // Auto-focus next input
//       if (value && index < 5) {
//         const nextInput = document.getElementById(`otp-${index + 1}`);
//         if (nextInput) nextInput.focus();
//       }
//     }
//   };

//   // Handle OTP paste
//   const handleOTPPaste = (e) => {
//     e.preventDefault();
//     const pastedData = e.clipboardData.getData('text');
//     if (/^\d{6}$/.test(pastedData)) {
//       const otpArray = pastedData.split('');
//       setFormData(prev => ({
//         ...prev,
//         otp: otpArray
//       }));
//     }
//   };

//   // // Handle form submission
//   // const handleSubmit = async (e) => {
//   //   e.preventDefault();
//   //   const newErrors = {};

//   //   if (!validateEmailOrMobile(formData.emailOrMobile)) {
//   //     newErrors.emailOrMobile = 'Please enter a valid email or mobile number';
//   //   }

//   //   if (Object.keys(newErrors).length === 0) {
//   //     try {
//   //       let contactValue = formData.emailOrMobile;
//   //       if (/^[0-9]{10}$/.test(formData.emailOrMobile)) {
//   //         contactValue = '+91' + formData.emailOrMobile;
//   //       }

//   //       if (!showOTP) {
//   //         // Send OTP logic stays the same
//   //         const data = new FormData();
//   //         data.append('action', 'sendotp');
//   //         data.append('email_mobile_userid', contactValue);

//   //         const response = await axios({
//   //           method: 'post',
//   //           url: 'http://creatorwerse.pmpframe.com/api/login.php',
//   //           data: data,
//   //           headers: { 'Content-Type': 'multipart/form-data' }
//   //         });

//   //         if (response.data) {
//   //           setShowOTP(true);
//   //           alert('OTP sent successfully!');
//   //         }
//   //       } else {
//   //         // Verify OTP logic with updated navigation
//   //         const otpValue = formData.otp.join('');
          
//   //         if (otpValue.length !== 6) {
//   //           setErrors({ otp: 'Please enter a valid 6-digit OTP' });
//   //           return;
//   //         }

//   //         const data = new FormData();
//   //         data.append('action', 'login');
//   //         data.append('otp', otpValue);
//   //         data.append('email_mobile_userid', contactValue);

//   //         // const response = await axios({
//   //         //   method: 'post',
//   //         //   url: 'http://creatorwerse.pmpframe.com/api/login.php',
//   //         //   data: data,
//   //         //   headers: { 'Content-Type': 'multipart/form-data' }
//   //         // });

//   //         const response = await fetch('http://creatorwerse.pmpframe.com/api/login.php', {
//   //           method: 'POST',
//   //           headers: { 'Content-Type': 'multipart/form-data' },
//   //           body: data
//   //       });

//   //         const res = await response.json();
//   //         console.log('Login Response:', res); 
//   //         if (res.success){
//   //              localStorage.setItem('user', JSON.stringify(res.data));
//   //             console.log('User data stored in localStorage'); // Debug log
//   //             navigate('/home');
//   //             alert (
//   //               res.message
//   //             )

//   //         }
//   //         else if (
//   //           !res.success
//   //         )
//   //         {
//   //           alert (
//   //             res.message
//   //           ) 
//   //         }
//   //           //  if (response.data && response.data.status === 'success') {
             
            
//   //           // if (response.data.data) {
//   //           //   localStorage.setItem('user', JSON.stringify(response.data.data));
//   //           //   console.log('User data stored in localStorage'); // Debug log
//   //           // }
            
//   //           // try {
//   //           //   alert('Login successful!');
//   //           //   console.log('Attempting navigation...'); // Debug log
              
//   //           //   // Try multiple navigation approaches
//   //           //   navigate('/home');
              
//   //           //   // Fallback navigation after a short delay
//   //           //   setTimeout(() => {
//   //           //     if (window.location.pathname !== '/home') {
//   //           //       console.log('Fallback navigation triggered'); // Debug log
//   //           //       window.location.href = '/home';
//   //           //     }
//   //           //   }, 500);
//   //           // } catch (navigationError) {
//   //           //   console.error('Navigation error:', navigationError);
//   //           //   // Final fallback
//   //           //   window.location.replace('/home');
//   //           // }

//   //         // } else {
//   //         //   console.log('Login failed:', response.data); // Debug log
//   //         //   alert(response.data?.message || 'Invalid OTP. Please try again.');
//   //         //   setFormData(prev => ({
//   //         //     ...prev,
//   //         //     otp: ['', '', '', '', '', '']
//   //         //   }));
//   //         //   const firstOtpInput = document.getElementById('otp-0');
//   //         //   if (firstOtpInput) firstOtpInput.focus();
//   //         // }
//   //       }
//   //     } catch (error) {
//   //       console.error('API Error:', error);
//   //       alert('An error occurred. Please try again.');
//   //     }
//   //   }

//   //   setErrors(newErrors);
//   // };



//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const newErrors = {};

//     if (!validateEmailOrMobile(formData.emailOrMobile)) {
//       newErrors.emailOrMobile = "Please enter a valid email or mobile number";
//     }

//     if (Object.keys(newErrors).length === 0) {
//       try {
//         let contactValue = formData.emailOrMobile;
//         if (/^[0-9]{10}$/.test(formData.emailOrMobile)) {
//           contactValue = "+91" + formData.emailOrMobile;
//         }

//         if (!showOTP) {
//           // Send OTP logic stays the same
//           const data = new FormData();
//           data.append("action", "sendotp");
//           data.append("email_mobile_userid", contactValue);

//           const response = await axios({
//             method: "post",
//             url: "http://creatorwerse.pmpframe.com/api/login.php",
//             data: data,
//             headers: { "Content-Type": "multipart/form-data" },
//           });

//           if (response.data) {
//             setShowOTP(true);
//             alert("OTP sent successfully!");
//           }
//         } else {
//           // Verify OTP logic with updated navigation
//           const otpValue = formData.otp.join("");

//           if (otpValue.length !== 6) {
//             setErrors({ otp: "Please enter a valid 6-digit OTP" });
//             return;
//           }

//           const data = new FormData();
//           data.append("action", "login");
//           data.append("otp", otpValue);
//           data.append("email_mobile_userid", contactValue);

//           // const response = await axios({
//           //   method: 'post',
//           //   url: 'http://creatorwerse.pmpframe.com/api/login.php',
//           //   data: data,
//           //   headers: { 'Content-Type': 'multipart/form-data' }
//           // });

//           const response = await fetch(
//             "http://creatorwerse.pmpframe.com/api/login.php",
//             {
//               method: "POST",
//               // headers: { "Content-Type": "multipart/form-data" },
//               body: data,
//             }
//           );
//           console.log("response ===", response);

//           const res = await response.json();
//           console.log("Login Response:", res);
//           if (res.success) {
//             localStorage.setItem("user", JSON.stringify(res.data));
//             console.log("User data stored in localStorage"); // Debug log
//             navigate("/reelscriptform");
//             alert(res.message);
//           } else if (!res.success) {
//             alert(res.message);
//           }else{
//             alert("Server Down")
//           }
//           //  if (response.data && response.data.status === 'success') {

//           // if (response.data.data) {
//           //   localStorage.setItem('user', JSON.stringify(response.data.data));
//           //   console.log('User data stored in localStorage'); // Debug log
//           // }

//           // try {
//           //   alert('Login successful!');
//           //   console.log('Attempting navigation...'); // Debug log

//           //   // Try multiple navigation approaches
//           //   navigate('/home');

//           //   // Fallback navigation after a short delay
//           //   setTimeout(() => {
//           //     if (window.location.pathname !== '/home') {
//           //       console.log('Fallback navigation triggered'); // Debug log
//           //       window.location.href = '/home';
//           //     }
//           //   }, 500);
//           // } catch (navigationError) {
//           //   console.error('Navigation error:', navigationError);
//           //   // Final fallback
//           //   window.location.replace('/home');
//           // }

//           // } else {
//           //   console.log('Login failed:', response.data); // Debug log
//           //   alert(response.data?.message || 'Invalid OTP. Please try again.');
//           //   setFormData(prev => ({
//           //     ...prev,
//           //     otp: ['', '', '', '', '', '']
//           //   }));
//           //   const firstOtpInput = document.getElementById('otp-0');
//           //   if (firstOtpInput) firstOtpInput.focus();
//           // }
//         }
//       } catch (error) {
//         console.error("API Error:", error);
//         alert("An error occurred. Please try again.");
//       }
//     }

//     setErrors(newErrors);
//   };

//   return (
//     // <div className="container mt-5">
//     <div  style={{ backgroundColor: 'black', minHeight: '100vh' }} >
//       <div className="row justify-content-center">
//         <div className="col-md-6">
//           <div className="card shadow">
//             <div className="card-body p-5">
//             <div 
//             className="text-center mb-4 py-4" 
//             style={{ 
//               backgroundColor: 'black',
//               width: '100%',
//               height: '200px',
//               display: 'flex',
//               flexDirection: 'column',
//               justifyContent: 'center',
//               alignItems: 'center',
//               borderRadius: '8px'
//             }}
//           >
//             <img 
//               src={CreatorWerse} 
//               alt="Logo" 
//               className="mb-3" 
//               style={{ 
//                 width: '150px',
//                 height: '100px',
//                 objectFit: 'contain'
//               }} 
//             />
//             <h2 className="text-white">Login</h2>
//           </div>
//               <form onSubmit={handleSubmit}>
//                 <div className="mb-3">
//                   <label className="form-label">Email or Mobile*</label>
//                   <input
//                     type="text"
//                     className={`form-control ${errors.emailOrMobile ? 'is-invalid' : ''}`}
//                     name="emailOrMobile"
//                     value={formData.emailOrMobile}
//                     onChange={handleInputChange}
//                     placeholder="Enter email or mobile"
//                   />
//                   {errors.emailOrMobile && (
//                     <div className="invalid-feedback">{errors.emailOrMobile}</div>
//                   )}
//                 </div>

//                 {showOTP && (
//                   <div className="mb-3">
//                     <label className="form-label">Enter OTP*</label>
//                     <div className="d-flex gap-2 justify-content-center">
//                       {[0, 1, 2, 3, 4, 5].map((index) => (
//                         <input
//                           key={index}
//                           id={`otp-${index}`}
//                           type="text"
//                           className={`form-control text-center ${errors.otp ? 'is-invalid' : ''}`}
//                           style={{ width: '50px' }}
//                           maxLength="1"
//                           value={formData.otp[index]}
//                           onChange={(e) => handleOTPChange(index, e.target.value)}
//                           onPaste={handleOTPPaste}
//                         />
//                       ))}
//                     </div>
//                     {errors.otp && (
//                       <div className="text-danger text-center mt-2">{errors.otp}</div>
//                     )}
//                   </div>
//                 )}

//                 <button  type="submit" className="btn btn-dark w-100 mt-3">
//                   {showOTP ? 'Login' : 'Get OTP'}
//                 </button>

//                 <div className="text-center mt-3">
//                   <span>Don't have an account? </span>
//                   <a href="/signup" className="text-decoration-none">
//                     Sign up
//                   </a>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;







// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import CreatorWerse from "../../assets/CreatorWerse.png"

// const Login = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     emailOrMobile: '',
//     userId: '',
//     otp: ['', '', '', '', '', '']
//   });
//   const [errors, setErrors] = useState({});
//   const [showOTP, setShowOTP] = useState(false);

//   // Validate email or mobile number
//   const validateEmailOrMobile = (value) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const mobileRegex = /^[0-9]{10}$/;
//     return emailRegex.test(value) || mobileRegex.test(value);
//   };

//   // Handle input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prev => ({
//       ...prev,
//       [name]: value
//     }));
//     setErrors(prev => ({
//       ...prev,
//       [name]: ''
//     }));
//   };

//   // Handle OTP input
//   const handleOTPChange = (index, value) => {
//     if (value.length <= 1 && /^\d*$/.test(value)) {
//       const newOTP = [...formData.otp];
//       newOTP[index] = value;
//       setFormData(prev => ({
//         ...prev,
//         otp: newOTP
//       }));

//       // Auto-focus next input
//       if (value && index < 5) {
//         const nextInput = document.getElementById(`otp-${index + 1}`);
//         if (nextInput) nextInput.focus();
//       }
//     }
//   };

//   // Handle OTP paste
//   const handleOTPPaste = (e) => {
//     e.preventDefault();
//     const pastedData = e.clipboardData.getData('text');
//     if (/^\d{6}$/.test(pastedData)) {
//       const otpArray = pastedData.split('');
//       setFormData(prev => ({
//         ...prev,
//         otp: otpArray
//       }));
//     }
//   };

  


//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const newErrors = {};

//     if (!validateEmailOrMobile(formData.emailOrMobile)) {
//       newErrors.emailOrMobile = "Please enter a valid email or mobile number";
//     }

//     if (Object.keys(newErrors).length === 0) {
//       try {
//         let contactValue = formData.emailOrMobile;
//         if (/^[0-9]{10}$/.test(formData.emailOrMobile)) {
//           contactValue = "+91" + formData.emailOrMobile;
//         }

//         if (!showOTP) {
//           // Send OTP logic stays the same
//           const data = new FormData();
//           data.append("action", "sendotp");
//           data.append("email_mobile_userid", contactValue);

//           const response = await axios({
//             method: "post",
//             url: "http://creatorwerse.pmpframe.com/api/login.php",
//             data: data,
//             headers: { "Content-Type": "multipart/form-data" },
//           });

//           if (response.data) {
//             setShowOTP(true);
//             alert("OTP sent successfully!");
//           }
//         } else {
//           // Verify OTP logic with updated navigation
//           const otpValue = formData.otp.join("");

//           if (otpValue.length !== 6) {
//             setErrors({ otp: "Please enter a valid 6-digit OTP" });
//             return;
//           }

//           const data = new FormData();
//           data.append("action", "login");
//           data.append("otp", otpValue);
//           data.append("email_mobile_userid", contactValue);

         

//           const response = await fetch(
//             "http://creatorwerse.pmpframe.com/api/login.php",
//             {
//               method: "POST",

//               body: data,
//             }
//           );
//           console.log("response ===", response);

//           const res = await response.json();
//           console.log("Login Response:", res);
//           if (res.success) {
//             localStorage.setItem("user", JSON.stringify(res.data));
//             console.log("User data stored in localStorage"); // Debug log
//             navigate("/reelscriptform");
//             alert(res.message);
//           } else if (!res.success) {
//             alert(res.message);
//           }else{
//             alert("Server Down")
//           }
         
//         }
//       } catch (error) {
//         console.error("API Error:", error);
//         alert("An error occurred. Please try again.");
//       }
//     }

//     setErrors(newErrors);
//   };

//   return (
//     // <div className="container mt-5">
//     <div  style={{ backgroundColor: 'black', minHeight: '100vh' }} >
//       <div className="row justify-content-center">
//         <div className="col-md-6">
//           <div className="card shadow">
//             <div className="card-body p-5">
//             <div 
//             className="text-center mb-4 py-4" 
//             style={{ 
//               backgroundColor: 'black',
//               width: '100%',
//               height: '200px',
//               display: 'flex',
//               flexDirection: 'column',
//               justifyContent: 'center',
//               alignItems: 'center',
//               borderRadius: '8px'
//             }}
//           >
//             <img 
//               src={CreatorWerse} 
//               alt="Logo" 
//               className="mb-3" 
//               style={{ 
//                 width: '150px',
//                 height: '100px',
//                 objectFit: 'contain'
//               }} 
//             />
//             <h2 className="text-white">Login</h2>
//           </div>
//               <form onSubmit={handleSubmit}>
//                 <div className="mb-3">
//                   <label className="form-label">Email or Mobile*</label>
//                   <input
//                     type="text"
//                     className={`form-control ${errors.emailOrMobile ? 'is-invalid' : ''}`}
//                     name="emailOrMobile"
//                     value={formData.emailOrMobile}
//                     onChange={handleInputChange}
//                     placeholder="Enter email or mobile"
//                   />
//                   {errors.emailOrMobile && (
//                     <div className="invalid-feedback">{errors.emailOrMobile}</div>
//                   )}
//                 </div>

//                 {showOTP && (
//                   <div className="mb-3">
//                     <label className="form-label">Enter OTP*</label>
//                     <div className="d-flex gap-2 justify-content-center">
//                       {[0, 1, 2, 3, 4, 5].map((index) => (
//                         <input
//                           key={index}
//                           id={`otp-${index}`}
//                           type="text"
//                           className={`form-control text-center ${errors.otp ? 'is-invalid' : ''}`}
//                           style={{ width: '50px' }}
//                           maxLength="1"
//                           value={formData.otp[index]}
//                           onChange={(e) => handleOTPChange(index, e.target.value)}
//                           onPaste={handleOTPPaste}
//                         />
//                       ))}
//                     </div>
//                     {errors.otp && (
//                       <div className="text-danger text-center mt-2">{errors.otp}</div>
//                     )}
//                   </div>
//                 )}

//                 <button  type="submit" className="btn btn-dark w-100 mt-3">
//                   {showOTP ? 'Login' : 'Get OTP'}
//                 </button>

//                 <div className="text-center mt-3">
//                   <span>Don't have an account? </span>
//                   <a href="/signup" className="text-decoration-none">
//                     Sign up
//                   </a>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;



// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import CreatorWerse from "../../assets/CreatorWerse.png"

// const Login = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     emailOrMobile: '',
//     userId: '',
//     otp: ['', '', '', '', '', '']
//   });
//   const [errors, setErrors] = useState({});
//   const [showOTP, setShowOTP] = useState(false);

//   // Validate email or mobile number
//   const validateEmailOrMobile = (value) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     const mobileRegex = /^[0-9]{10}$/;
//     return emailRegex.test(value) || mobileRegex.test(value);
//   };

//   // Handle input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prev => ({
//       ...prev,
//       [name]: value
//     }));
//     setErrors(prev => ({
//       ...prev,
//       [name]: ''
//     }));
//   };

//   // Handle OTP input
//   const handleOTPChange = (index, value) => {
//     if (value.length <= 1 && /^\d*$/.test(value)) {
//       const newOTP = [...formData.otp];
//       newOTP[index] = value;
//       setFormData(prev => ({
//         ...prev,
//         otp: newOTP
//       }));

//       // Auto-focus next input
//       if (value && index < 5) {
//         const nextInput = document.getElementById(`otp-${index + 1}`);
//         if (nextInput) nextInput.focus();
//       }
//     }
//   };

//   // Handle OTP paste
//   const handleOTPPaste = (e) => {
//     e.preventDefault();
//     const pastedData = e.clipboardData.getData('text');
//     if (/^\d{6}$/.test(pastedData)) {
//       const otpArray = pastedData.split('');
//       setFormData(prev => ({
//         ...prev,
//         otp: otpArray
//       }));
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const newErrors = {};

//     if (!validateEmailOrMobile(formData.emailOrMobile)) {
//       newErrors.emailOrMobile = "Please enter a valid email or mobile number";
//     }

//     if (Object.keys(newErrors).length === 0) {
//       try {
//         let contactValue = formData.emailOrMobile;
//         if (/^[0-9]{10}$/.test(formData.emailOrMobile)) {
//           contactValue = "+91" + formData.emailOrMobile;
//         }

//         if (!showOTP) {
//           const data = new FormData();
//           data.append("action", "sendotp");
//           data.append("email_mobile_userid", contactValue);

//           const response = await axios({
//             method: "post",
//             url: "http://creatorwerse.pmpframe.com/api/login.php",
//             data: data,
//             headers: { "Content-Type": "multipart/form-data" },
//           });

//           if (response.data) {
//             setShowOTP(true);
//             alert("OTP sent successfully!");
//           }
//         } else {
//           const otpValue = formData.otp.join("");

//           if (otpValue.length !== 6) {
//             setErrors({ otp: "Please enter a valid 6-digit OTP" });
//             return;
//           }

//           const data = new FormData();
//           data.append("action", "login");
//           data.append("otp", otpValue);
//           data.append("email_mobile_userid", contactValue);

//           const response = await fetch(
//             "http://creatorwerse.pmpframe.com/api/login.php",
//             {
//               method: "POST",
//               body: data,
//             }
//           );
//           console.log("response ===", response);

//           const res = await response.json();
//           console.log("Login Response:", res);
//           if (res.success) {
//             localStorage.setItem("user", JSON.stringify(res.data));
//             console.log("User data stored in localStorage");
//             navigate("/reelscriptform");
//             alert(res.message);
//           } else if (!res.success) {
//             alert(res.message);
//           } else {
//             alert("Server Down")
//           }
//         }
//       } catch (error) {
//         console.error("API Error:", error);
//         alert("An error occurred. Please try again.");
//       }
//     }

//     setErrors(newErrors);
//   };

//   return (
//     <div style={{ backgroundColor: 'black', minHeight: '100vh' }}>
//       <div className="row justify-content-center">
//         <div className="col-md-6">
//           <div className="card shadow" style={{ backgroundColor: 'black'}}>
//             <div className="card-body p-5">
//               <div 
//                 className="text-center mb-4 py-4" 
//                 style={{ 
//                   backgroundColor: 'black',
//                   width: '100%',
//                   height: '200px',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   borderRadius: '8px'
//                 }}
//               >
//                 <img 
//                   src={CreatorWerse} 
//                   alt="Logo" 
//                   className="mb-3" 
//                   style={{ 
//                     width: '150px',
//                     height: '100px',
//                     objectFit: 'contain'
//                   }} 
//                 />
//                 <h2 className="text-white">Login</h2>
//               </div>
//               <form onSubmit={handleSubmit}>
//                 <div className="mb-3">
//                   <label className="form-label text-white">Email or Mobile*</label>
//                   <input
//                     type="text"
//                     className={`form-control ${errors.emailOrMobile ? 'is-invalid' : ''}`}
//                     name="emailOrMobile"
//                     value={formData.emailOrMobile}
//                     onChange={handleInputChange}
//                     placeholder="Enter email or mobile"
//                     style={{
//                       backgroundColor: 'black',
//                       color: 'white',
//                       border: '1px solid white'
//                     }}
//                   />
//                   {errors.emailOrMobile && (
//                     <div className="invalid-feedback text-white">{errors.emailOrMobile}</div>
//                   )}
//                 </div>

//                 {showOTP && (
//                   <div className="mb-3">
//                     <label className="form-label text-white">Enter OTP*</label>
//                     <div className="d-flex gap-2 justify-content-center">
//                       {[0, 1, 2, 3, 4, 5].map((index) => (
//                         <input
//                           key={index}
//                           id={`otp-${index}`}
//                           type="text"
//                           className={`form-control text-center ${errors.otp ? 'is-invalid' : ''}`}
//                           style={{ 
//                             width: '50px',
//                             backgroundColor: 'black',
//                             color: 'white',
//                             border: '1px solid white'
//                           }}
//                           maxLength="1"
//                           value={formData.otp[index]}
//                           onChange={(e) => handleOTPChange(index, e.target.value)}
//                           onPaste={handleOTPPaste}
//                         />
//                       ))}
//                     </div>
//                     {errors.otp && (
//                       <div className="text-white text-center mt-2">{errors.otp}</div>
//                     )}
//                   </div>
//                 )}

//                 {/* <button type="submit" className="btn btn-light w-100 mt-3">
//                   {showOTP ? 'Login' : 'Get OTP'}
//                 </button> */}
//                      <button 
//                     type="submit" 
//                     className="btn"
//                     style={{
//                       background: 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))',
//                       color: 'white',
//                       border: '1px solid rgba(0, 255, 128, 0.3)',
//                       padding: '12px',
//                       transition: 'all 0.4s ease',
//                       boxShadow: '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)',
//                       fontWeight: '600',
//                       letterSpacing: '0.5px',
//                       position: 'relative',
//                       overflow: 'hidden',
//                       borderRadius: '6px',
//                       fontSize: '16px',
//                     }}
//                     onMouseOver={(e) => {
//                       e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.95), rgba(0, 200, 100, 0.95))';
//                       e.target.style.borderColor = 'rgba(0, 255, 128, 0.8)';
//                       e.target.style.boxShadow = '0 6px 25px rgba(0, 170, 85, 0.4), 0 0 30px rgba(0, 255, 128, 0.4)';
//                       e.target.style.transform = 'translateY(-2px) scale(1.01)';
//                       e.target.style.letterSpacing = '1px';
//                     }}
//                     onMouseOut={(e) => {
//                       e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))';
//                       e.target.style.borderColor = 'rgba(0, 255, 128, 0.3)';
//                       e.target.style.boxShadow = '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)';
//                       e.target.style.transform = 'translateY(0) scale(1)';
//                       e.target.style.letterSpacing = '0.5px';
//                     }}
                   
//                   >
//                     <span style={{ 
//                       position: 'relative',
//                       zIndex: 1,
//                       textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
//                       display: 'inline-block'
//                     }}>
//                       {showOTP ? 'Login' : 'Get OTP'}
//                     </span>
//                   </button>

//                 <div className="text-center mt-3">
//                   <span className="text-white">Don't have an account? </span>
//                   <a href="/signup" className="text-white text-decoration-none" style={{ borderBottom: '1px solid white' }}>
//                     Sign up
//                   </a>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;



// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import CreatorWerse from "../../assets/CreatorWerse.png"

// const Login = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     emailOrMobile: '',
//     userId: '',
//     otp: ['', '', '', '', '', '']
//   });
//   const [errors, setErrors] = useState({});
//   const [showOTP, setShowOTP] = useState(false);
//   const [resendTimer, setResendTimer] = useState(0);

//   // Validate email
//   const validateEmail = (value) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(value);
//   };

//   // Handle input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prev => ({
//       ...prev,
//       [name]: value
//     }));
//     setErrors(prev => ({
//       ...prev,
//       [name]: ''
//     }));
//   };

//   // Handle OTP input
//   const handleOTPChange = (index, value) => {
//     if (value.length <= 1 && /^\d*$/.test(value)) {
//       const newOTP = [...formData.otp];
//       newOTP[index] = value;
//       setFormData(prev => ({
//         ...prev,
//         otp: newOTP
//       }));

//       // Auto-focus next input
//       if (value && index < 5) {
//         const nextInput = document.getElementById(`otp-${index + 1}`);
//         if (nextInput) nextInput.focus();
//       }
//     }
//   };

//   // Handle OTP paste
//   const handleOTPPaste = (e) => {
//     e.preventDefault();
//     const pastedData = e.clipboardData.getData('text');
//     if (/^\d{6}$/.test(pastedData)) {
//       const otpArray = pastedData.split('');
//       setFormData(prev => ({
//         ...prev,
//         otp: otpArray
//       }));
//     }
//   };

//   // Start resend timer
//   const startResendTimer = () => {
//     setResendTimer(30);
//     const timer = setInterval(() => {
//       setResendTimer((prev) => {
//         if (prev <= 1) {
//           clearInterval(timer);
//           return 0;
//         }
//         return prev - 1;
//       });
//     }, 1000);
//   };

//   // Handle resend OTP
//   const handleResendOTP = async () => {
//     if (resendTimer > 0) return;

//     try {
//       const data = new FormData();
//       data.append("action", "sendotp");
//       data.append("email_mobile_userid", formData.emailOrMobile);

//       const response = await axios({
//         method: "post",
//         url: "http://creatorwerse.pmpframe.com/api/login.php",
//         data: data,
//         headers: { "Content-Type": "multipart/form-data" },
//       });

//       if (response.data) {
//         alert("OTP resent successfully!");
//         startResendTimer();
//       }
//     } catch (error) {
//       console.error("Resend OTP Error:", error);
//       alert("Failed to resend OTP. Please try again.");
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const newErrors = {};

//     if (!validateEmail(formData.emailOrMobile)) {
//       newErrors.emailOrMobile = "Please enter a valid email";
//     }

//     if (Object.keys(newErrors).length === 0) {
//       try {
//         const contactValue = formData.emailOrMobile;

//         if (!showOTP) {
//           const data = new FormData();
//           data.append("action", "sendotp");
//           data.append("email_mobile_userid", contactValue);

//           const response = await axios({
//             method: "post",
//             url: "http://creatorwerse.pmpframe.com/api/login.php",
//             data: data,
//             headers: { "Content-Type": "multipart/form-data" },
//           });

//           if (response.data) {
//             setShowOTP(true);
//             startResendTimer();
//             alert("OTP sent successfully!");
//           }
//         } else {
//           const otpValue = formData.otp.join("");

//           if (otpValue.length !== 6) {
//             setErrors({ otp: "Please enter a valid 6-digit OTP" });
//             return;
//           }

//           const data = new FormData();
//           data.append("action", "login");
//           data.append("otp", otpValue);
//           data.append("email_mobile_userid", contactValue);

//           const response = await fetch(
//             "http://creatorwerse.pmpframe.com/api/login.php",
//             {
//               method: "POST",
//               body: data,
//             }
//           );

//           const res = await response.json();
//           console.log("Login Response:", res);
//           if (res.success) {
//             localStorage.setItem("user", JSON.stringify(res.data));
//             console.log("User data stored in localStorage");
//             navigate("/reelscriptform");
//             alert(res.message);
//           } else if (!res.success) {
//             alert(res.message);
//           } else {
//             alert("Server Down")
//           }
//         }
//       } catch (error) {
//         console.error("API Error:", error);
//         alert("An error occurred. Please try again.");
//       }
//     }

//     setErrors(newErrors);
//   };

//   return (
//     <div style={{ backgroundColor: 'black', minHeight: '100vh' }}>
//       <div className="row justify-content-center">
//         <div className="col-md-6">
//           <div className="card shadow" style={{ backgroundColor: 'black'}}>
//             <div className="card-body p-5">
//               <div 
//                 className="text-center mb-4 py-4" 
//                 style={{ 
//                   backgroundColor: 'black',
//                   width: '100%',
//                   height: '200px',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   borderRadius: '8px'
//                 }}
//               >
//                 <img 
//                   src={CreatorWerse} 
//                   alt="Logo" 
//                   className="mb-3" 
//                   style={{ 
//                     width: '150px',
//                     height: '100px',
//                     objectFit: 'contain'
//                   }} 
//                 />
//                 <h2 className="text-white">Login</h2>
//               </div>
//               <form onSubmit={handleSubmit}>
//                 <div className="mb-3">
//                   <label className="form-label text-white">Email*</label>
//                   <input
//                     type="text"
//                     className={`form-control ${errors.emailOrMobile ? 'is-invalid' : ''}`}
//                     name="emailOrMobile"
//                     value={formData.emailOrMobile}
//                     onChange={handleInputChange}
//                     placeholder="Enter email"
//                     style={{
//                       backgroundColor: 'black',
//                       color: 'white',
//                       border: '1px solid white'
//                     }}
//                   />
//                   {errors.emailOrMobile && (
//                     <div className="invalid-feedback text-white">{errors.emailOrMobile}</div>
//                   )}
//                 </div>

//                 {showOTP && (
//                   <div className="mb-3">
//                     <label className="form-label text-white">Enter OTP*</label>
//                     <div className="d-flex gap-2 justify-content-center">
//                       {[0, 1, 2, 3, 4, 5].map((index) => (
//                         <input
//                           key={index}
//                           id={`otp-${index}`}
//                           type="text"
//                           className={`form-control text-center ${errors.otp ? 'is-invalid' : ''}`}
//                           style={{ 
//                             width: '50px',
//                             backgroundColor: 'black',
//                             color: 'white',
//                             border: '1px solid white'
//                           }}
//                           maxLength="1"
//                           value={formData.otp[index]}
//                           onChange={(e) => handleOTPChange(index, e.target.value)}
//                           onPaste={handleOTPPaste}
//                         />
//                       ))}
//                     </div>
//                     {errors.otp && (
//                       <div className="text-white text-center mt-2">{errors.otp}</div>
//                     )}
//                     <div className="text-center mt-3">
//                       <button
//                         type="button"
//                         onClick={handleResendOTP}
//                         className="btn btn-link text-white text-decoration-none"
//                         disabled={resendTimer > 0}
//                       >
//                         {resendTimer > 0 ? `Resend OTP in ${resendTimer}s` : 'Resend OTP'}
//                       </button>
//                     </div>
//                   </div>
//                 )}

//                 <div className="text-center">
//                   <button 
//                     type="submit" 
//                     className="btn"
//                     style={{
//                       background: 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))',
//                       color: 'white',
//                       border: '1px solid rgba(0, 255, 128, 0.3)',
//                       padding: '12px',
//                       transition: 'all 0.4s ease',
//                       boxShadow: '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)',
//                       fontWeight: '600',
//                       letterSpacing: '0.5px',
//                       position: 'relative',
//                       overflow: 'hidden',
//                       borderRadius: '6px',
//                       fontSize: '16px',
//                       width: '200px',
//                       margin: '0 auto'
//                     }}
//                     onMouseOver={(e) => {
//                       e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.95), rgba(0, 200, 100, 0.95))';
//                       e.target.style.borderColor = 'rgba(0, 255, 128, 0.8)';
//                       e.target.style.boxShadow = '0 6px 25px rgba(0, 170, 85, 0.4), 0 0 30px rgba(0, 255, 128, 0.4)';
//                       e.target.style.transform = 'translateY(-2px) scale(1.01)';
//                       e.target.style.letterSpacing = '1px';
//                     }}
//                     onMouseOut={(e) => {
//                       e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))';
//                       e.target.style.borderColor = 'rgba(0, 255, 128, 0.3)';
//                       e.target.style.boxShadow = '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)';
//                       e.target.style.transform = 'translateY(0) scale(1)';
//                       e.target.style.letterSpacing = '0.5px';
//                     }}
//                   >
//                     <span style={{ 
//                       position: 'relative',
//                       zIndex: 1,
//                       textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
//                       display: 'inline-block'
//                     }}>
//                       {showOTP ? 'Login' : 'Get OTP'}
//                     </span>
//                   </button>
//                 </div>

//                 <div className="text-center mt-3">
//                   <span className="text-white">Don't have an account? </span>
//                   <a href="/signup" className="text-white text-decoration-none" style={{ borderBottom: '1px solid white' }}>
//                     Sign up
//                   </a>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;



// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import CreatorWerse from "../../assets/CreatorWerse.png"

// const Login = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     emailOrMobile: '',
//     userId: '',
//     otp: ['', '', '', '', '', '']
//   });
//   const [errors, setErrors] = useState({});
//   const [showOTP, setShowOTP] = useState(false);
//   const [resendTimer, setResendTimer] = useState(0);

//   // Validate email
//   const validateEmail = (value) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(value);
//   };

//   // Handle input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prev => ({
//       ...prev,
//       [name]: value
//     }));
//     setErrors(prev => ({
//       ...prev,
//       [name]: ''
//     }));
//   };

//   // Handle OTP input
//   const handleOTPChange = (index, value) => {
//     if (value.length <= 1 && /^\d*$/.test(value)) {
//       const newOTP = [...formData.otp];
//       newOTP[index] = value;
//       setFormData(prev => ({
//         ...prev,
//         otp: newOTP
//       }));

//       // Auto-focus next input
//       if (value && index < 5) {
//         const nextInput = document.getElementById(`otp-${index + 1}`);
//         if (nextInput) nextInput.focus();
//       }
//     }
//   };

//   // Handle OTP paste
//   const handleOTPPaste = (e) => {
//     e.preventDefault();
//     const pastedData = e.clipboardData.getData('text');
//     if (/^\d{6}$/.test(pastedData)) {
//       const otpArray = pastedData.split('');
//       setFormData(prev => ({
//         ...prev,
//         otp: otpArray
//       }));
//     }
//   };

//   // Start resend timer
//   const startResendTimer = () => {
//     setResendTimer(30);
//     const timer = setInterval(() => {
//       setResendTimer((prev) => {
//         if (prev <= 1) {
//           clearInterval(timer);
//           return 0;
//         }
//         return prev - 1;
//       });
//     }, 1000);
//   };

//   // Handle resend OTP
//   const handleResendOTP = async () => {
//     if (resendTimer > 0) return;

//     try {
//       const data = new FormData();
//       data.append("action", "sendotp");
//       data.append("email_mobile_userid", formData.emailOrMobile);

//       const response = await axios({
//         method: "post",
//         url: "https://creatorwerse.pmpframe.com/api/login.php",
//         data: data,
//         headers: { "Content-Type": "multipart/form-data" },
//       });

//       if (response.data) {
//         alert("OTP resent successfully!");
//         startResendTimer();
//       }
//     } catch (error) {
//       console.error("Resend OTP Error:", error);
//       alert("Failed to resend OTP. Please try again.");
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const newErrors = {};

//     if (!validateEmail(formData.emailOrMobile)) {
//       newErrors.emailOrMobile = "Please enter a valid email";
//     }

//     if (Object.keys(newErrors).length === 0) {
//       try {
//         const contactValue = formData.emailOrMobile;

//         if (!showOTP) {
//           const data = new FormData();
//           data.append("action", "sendotp");
//           data.append("email_mobile_userid", contactValue);

//           const response = await axios({
//             method: "post",
//             url: "https://creatorwerse.pmpframe.com/api/login.php",
//             data: data,
//             headers: { "Content-Type": "multipart/form-data" },
//           });

//           // Check if response.data exists and handle the account activation status
//           if (response.data) {
//             if (response.data.account_status === "inactive") {
//               alert("Your account is not activated. Please check your email for activation instructions or contact support.");
//               return;
//             }
            
//             if (response.data.success) {
//               setShowOTP(true);
//               startResendTimer();
//               alert("OTP sent successfully!");
//             } else {
//               // Handle other error cases
//               alert(response.data.message || "Failed to send OTP. Please try again.");
//             }
//           }
//         } else {
//           const otpValue = formData.otp.join("");

//           if (otpValue.length !== 6) {
//             setErrors({ otp: "Please enter a valid 6-digit OTP" });
//             return;
//           }

//           const data = new FormData();
//           data.append("action", "login");
//           data.append("otp", otpValue);
//           data.append("email_mobile_userid", contactValue);

//           const response = await fetch(
//             "https://creatorwerse.pmpframe.com/api/login.php",
//             {
//               method: "POST",
//               body: data,
//             }
//           );

//           const res = await response.json();
//           console.log("Login Response:", res);
          
//           if (res.account_status === "inactive") {
//             alert("Your account is not activated. Please check your email for activation instructions or contact support.");
//             return;
//           }
          
//           if (res.success) {
//             localStorage.setItem("user", JSON.stringify(res.data));
//             console.log("User data stored in localStorage");
//             navigate("/reelscriptform");
//             alert(res.message);
//           } else {
//             alert(res.message || "Login failed. Please try again.");
//           }
//         }
//       } catch (error) {
//         console.error("API Error:", error);
//         alert("An error occurred. Please try again.");
//       }
//     }

//     setErrors(newErrors);
// };

//   return (
//     <div className="container-fluid" style={{ backgroundColor: 'black', minHeight: '100vh', overflow: 'hidden' }}>
//       <div className="container h-100">
//         <div className="row justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
//           <div className="col-12 col-md-6 px-3">
//             <div className="card shadow" style={{ backgroundColor: 'black', border: 'none' }}>
//               <div className="card-body p-4">
//                 <div 
//                   className="text-center mb-4" 
//                   style={{ 
//                     backgroundColor: 'black',
//                     width: '100%',
//                     display: 'flex',
//                     flexDirection: 'column',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     borderRadius: '8px'
//                   }}
//                 >
//                   <img 
//                     src={CreatorWerse} 
//                     alt="Logo" 
//                     className="mb-3" 
//                     style={{ 
//                       width: '150px',
//                       height: '100px',
//                       objectFit: 'contain'
//                     }} 
//                   />
//                   <h2 className="text-white">Login</h2>
//                 </div>
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label className="form-label text-white">Email*</label>
//                     <input
//                       type="text"
//                       className={`form-control ${errors.emailOrMobile ? 'is-invalid' : ''}`}
//                       name="emailOrMobile"
//                       value={formData.emailOrMobile}
//                       onChange={handleInputChange}
//                       placeholder="Enter email"
//                       style={{
//                         backgroundColor: 'black',
//                         color: 'white',
//                         border: '1px solid white'
//                       }}
//                     />
//                     {errors.emailOrMobile && (
//                       <div className="invalid-feedback text-white">{errors.emailOrMobile}</div>
//                     )}
//                   </div>

//                   {showOTP && (
//                     <div className="mb-3">
//                       <label className="form-label text-white">Enter OTP*</label>
//                       <div className="d-flex gap-2 justify-content-center">
//                         {[0, 1, 2, 3, 4, 5].map((index) => (
//                           <input
//                             key={index}
//                             id={`otp-${index}`}
//                             type="text"
//                             className={`form-control text-center ${errors.otp ? 'is-invalid' : ''}`}
//                             style={{ 
//                               width: '45px',
//                               backgroundColor: 'black',
//                               color: 'white',
//                               border: '1px solid white'
//                             }}
//                             maxLength="1"
//                             value={formData.otp[index]}
//                             onChange={(e) => handleOTPChange(index, e.target.value)}
//                             onPaste={handleOTPPaste}
//                           />
//                         ))}
//                       </div>
//                       {errors.otp && (
//                         <div className="text-white text-center mt-2">{errors.otp}</div>
//                       )}
//                       <div className="text-center mt-3">
//                         <button
//                           type="button"
//                           onClick={handleResendOTP}
//                           className="btn btn-link text-white text-decoration-none"
//                           disabled={resendTimer > 0}
//                         >
//                           {resendTimer > 0 ? `Resend OTP in ${resendTimer}s` : 'Resend OTP'}
//                         </button>
//                       </div>
//                     </div>
//                   )}

//                   <div className="text-center mt-4">
//                     <button 
//                       type="submit" 
//                       className="btn"
//                       style={{
//                         background: 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))',
//                         color: 'white',
//                         border: '1px solid rgba(0, 255, 128, 0.3)',
//                         padding: '12px',
//                         transition: 'all 0.4s ease',
//                         boxShadow: '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)',
//                         fontWeight: '600',
//                         letterSpacing: '0.5px',
//                         position: 'relative',
//                         overflow: 'hidden',
//                         borderRadius: '6px',
//                         fontSize: '16px',
//                         width: '200px'
//                       }}
//                       onMouseOver={(e) => {
//                         e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.95), rgba(0, 200, 100, 0.95))';
//                         e.target.style.borderColor = 'rgba(0, 255, 128, 0.8)';
//                         e.target.style.boxShadow = '0 6px 25px rgba(0, 170, 85, 0.4), 0 0 30px rgba(0, 255, 128, 0.4)';
//                         e.target.style.transform = 'translateY(-2px) scale(1.01)';
//                         e.target.style.letterSpacing = '1px';
//                       }}
//                       onMouseOut={(e) => {
//                         e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))';
//                         e.target.style.borderColor = 'rgba(0, 255, 128, 0.3)';
//                         e.target.style.boxShadow = '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)';
//                         e.target.style.transform = 'translateY(0) scale(1)';
//                         e.target.style.letterSpacing = '0.5px';
//                       }}
//                     >
//                       <span style={{ 
//                         position: 'relative',
//                         zIndex: 1,
//                         textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
//                         display: 'inline-block'
//                       }}>
//                         {showOTP ? 'Login' : 'Get OTP'}
//                       </span>
//                     </button>
//                   </div>

//                   <div className="text-center mt-3">
//                     <span className="text-white">Don't have an account? </span>
//                     <a href="/signup" className="text-white text-decoration-none" style={{ borderBottom: '1px solid white' }}>
//                       Sign up
//                     </a>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;


// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import CreatorWerse from "../../assets/CreatorWerse.png";

// // Custom Alert Component
// const CustomAlert = ({ message, type }) => {
//   if (!message) return null;
  
//   return (
//     <div 
//       style={{
//         position: 'fixed',
//         top: '20px',
//         left: '50%',
//         transform: 'translateX(-50%)',
//         zIndex: 1000,
//         padding: '12px 24px',
//         borderRadius: '4px',
//         background: type === 'error' ? 'rgba(220, 38, 38, 0.9)' : 'rgba(22, 163, 74, 0.9)',
//         color: 'white',
//         boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
//         textAlign: 'center',
//         maxWidth: '90%',
//         width: 'auto'
//       }}
//     >
//       {message}
//     </div>
//   );
// };

// const Login = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     emailOrMobile: '',
//     userId: '',
//     otp: ['', '', '', '', '', '']
//   });
//   const [errors, setErrors] = useState({});
//   const [showOTP, setShowOTP] = useState(false);
//   const [resendTimer, setResendTimer] = useState(0);
//   const [alert, setAlert] = useState({ message: '', type: 'success' });

//   const showAlert = (message, type = 'success') => {
//     setAlert({ message, type });
//     setTimeout(() => setAlert({ message: '', type: 'success' }), 3000);
//   };

//   const validateEmail = (value) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(value);
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prev => ({
//       ...prev,
//       [name]: value
//     }));
//     setErrors(prev => ({
//       ...prev,
//       [name]: ''
//     }));
//   };

//   const handleOTPChange = (index, value) => {
//     if (value.length <= 1 && /^\d*$/.test(value)) {
//       const newOTP = [...formData.otp];
//       newOTP[index] = value;
//       setFormData(prev => ({
//         ...prev,
//         otp: newOTP
//       }));

//       if (value && index < 5) {
//         const nextInput = document.getElementById(`otp-${index + 1}`);
//         if (nextInput) nextInput.focus();
//       }
//     }
//   };

//   const handleOTPPaste = (e) => {
//     e.preventDefault();
//     const pastedData = e.clipboardData.getData('text');
//     if (/^\d{6}$/.test(pastedData)) {
//       const otpArray = pastedData.split('');
//       setFormData(prev => ({
//         ...prev,
//         otp: otpArray
//       }));
//     }
//   };

//   const startResendTimer = () => {
//     setResendTimer(30);
//     const timer = setInterval(() => {
//       setResendTimer((prev) => {
//         if (prev <= 1) {
//           clearInterval(timer);
//           return 0;
//         }
//         return prev - 1;
//       });
//     }, 1000);
//   };

//   const handleResendOTP = async () => {
//     if (resendTimer > 0) return;

//     try {
//       const data = new FormData();
//       data.append("action", "sendotp");
//       data.append("email_mobile_userid", formData.emailOrMobile);

//       const response = await axios({
//         method: "post",
//         url: "https://creatorwerse.pmpframe.com/api/login.php",
//         data: data,
//         headers: { "Content-Type": "multipart/form-data" },
//       });

//       if (response.data) {
//         showAlert("OTP resent successfully!");
//         startResendTimer();
//       }
//     } catch (error) {
//       console.error("Resend OTP Error:", error);
//       showAlert("Failed to resend OTP. Please try again.", "error");
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const newErrors = {};

//     if (!validateEmail(formData.emailOrMobile)) {
//       newErrors.emailOrMobile = "Please enter a valid email";
//     }

//     if (Object.keys(newErrors).length === 0) {
//       try {
//         const contactValue = formData.emailOrMobile;

//         if (!showOTP) {
//           const data = new FormData();
//           data.append("action", "sendotp");
//           data.append("email_mobile_userid", contactValue);

//           const response = await axios({
//             method: "post",
//             url: "https://creatorwerse.pmpframe.com/api/login.php",
//             data: data,
//             headers: { "Content-Type": "multipart/form-data" },
//           });

//           if (response.data) {
//             if (response.data.account_status === "inactive") {
//               showAlert("Your account is not activated. Please check your email for activation instructions or contact support.", "error");
//               return;
//             }
            
//             if (response.data.success) {
//               setShowOTP(true);
//               startResendTimer();
//               showAlert("OTP sent successfully!");
//             } else {
//               showAlert(response.data.message || "Failed to send OTP. Please try again.", "error");
//             }
//           }
//         } else {
//           const otpValue = formData.otp.join("");

//           if (otpValue.length !== 6) {
//             setErrors({ otp: "Please enter a valid 6-digit OTP" });
//             return;
//           }

//           const data = new FormData();
//           data.append("action", "login");
//           data.append("otp", otpValue);
//           data.append("email_mobile_userid", contactValue);

//           const response = await fetch(
//             "https://creatorwerse.pmpframe.com/api/login.php",
//             {
//               method: "POST",
//               body: data,
//             }
//           );

//           const res = await response.json();
//           console.log("Login Response:", res);
          
//           if (res.account_status === "inactive") {
//             showAlert("Your account is not activated. Please check your email for activation instructions or contact support.", "error");
//             return;
//           }
          
//           if (res.success) {
//             localStorage.setItem("user", JSON.stringify(res.data));
//             showAlert(res.message);
//             setTimeout(() => {
//               navigate("/reelscriptform");
//             }, 1500);
//           } else {
//             showAlert(res.message || "Login failed. Please try again.", "error");
//           }
//         }
//       } catch (error) {
//         console.error("API Error:", error);
//         showAlert("An error occurred. Please try again.", "error");
//       }
//     }

//     setErrors(newErrors);
//   };

//   return (
//     <div style={{ 
//       position: 'fixed',
//       inset: 0,
//       backgroundColor: 'black',
//       overflow: 'auto'
//     }}>
//       <CustomAlert message={alert.message} type={alert.type} />
//       <div className="container h-100">
//         <div className="row justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
//           <div className="col-12 col-md-6 px-3">
//             <div className="card shadow" style={{ backgroundColor: 'black', border: 'none' }}>
//               <div className="card-body p-4">
//                 <div 
//                   className="text-center mb-4" 
//                   style={{ 
//                     backgroundColor: 'black',
//                     width: '100%',
//                     display: 'flex',
//                     flexDirection: 'column',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     borderRadius: '8px'
//                   }}
//                 >
//                   <img 
//                     src={CreatorWerse} 
//                     alt="Logo" 
//                     className="mb-3" 
//                     style={{ 
//                       width: '150px',
//                       height: '100px',
//                       objectFit: 'contain'
//                     }} 
//                   />
//                   <h2 className="text-white">Login</h2>
//                 </div>
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label className="form-label text-white">Email*</label>
//                     <input
//                       type="text"
//                       className={`form-control ${errors.emailOrMobile ? 'is-invalid' : ''}`}
//                       name="emailOrMobile"
//                       value={formData.emailOrMobile}
//                       onChange={handleInputChange}
//                       placeholder="Enter email"
//                       style={{
//                         backgroundColor: 'black',
//                         color: 'white',
//                         border: '1px solid white'
//                       }}
//                     />
//                     {errors.emailOrMobile && (
//                       <div className="invalid-feedback text-white">{errors.emailOrMobile}</div>
//                     )}
//                   </div>

//                   {showOTP && (
//                     <div className="mb-3">
//                       <label className="form-label text-white">Enter OTP*</label>
//                       <div className="d-flex gap-2 justify-content-center">
//                         {[0, 1, 2, 3, 4, 5].map((index) => (
//                           <input
//                             key={index}
//                             id={`otp-${index}`}
//                             type="text"
//                             className={`form-control text-center ${errors.otp ? 'is-invalid' : ''}`}
//                             style={{ 
//                               width: '45px',
//                               backgroundColor: 'black',
//                               color: 'white',
//                               border: '1px solid white'
//                             }}
//                             maxLength="1"
//                             value={formData.otp[index]}
//                             onChange={(e) => handleOTPChange(index, e.target.value)}
//                             onPaste={handleOTPPaste}
//                           />
//                         ))}
//                       </div>
//                       {errors.otp && (
//                         <div className="text-white text-center mt-2">{errors.otp}</div>
//                       )}
//                       <div className="text-center mt-3">
//                         <button
//                           type="button"
//                           onClick={handleResendOTP}
//                           className="btn btn-link text-white text-decoration-none"
//                           disabled={resendTimer > 0}
//                         >
//                           {resendTimer > 0 ? `Resend OTP in ${resendTimer}s` : 'Resend OTP'}
//                         </button>
//                       </div>
//                     </div>
//                   )}

//                   <div className="text-center mt-4">
//                     <button 
//                       type="submit" 
//                       className="btn"
//                       style={{
//                         background: 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))',
//                         color: 'white',
//                         border: '1px solid rgba(0, 255, 128, 0.3)',
//                         padding: '12px',
//                         transition: 'all 0.4s ease',
//                         boxShadow: '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)',
//                         fontWeight: '600',
//                         letterSpacing: '0.5px',
//                         position: 'relative',
//                         overflow: 'hidden',
//                         borderRadius: '6px',
//                         fontSize: '16px',
//                         width: '200px'
//                       }}
//                       onMouseOver={(e) => {
//                         e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.95), rgba(0, 200, 100, 0.95))';
//                         e.target.style.borderColor = 'rgba(0, 255, 128, 0.8)';
//                         e.target.style.boxShadow = '0 6px 25px rgba(0, 170, 85, 0.4), 0 0 30px rgba(0, 255, 128, 0.4)';
//                         e.target.style.transform = 'translateY(-2px) scale(1.01)';
//                         e.target.style.letterSpacing = '1px';
//                       }}
//                       onMouseOut={(e) => {
//                         e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))';
//                         e.target.style.borderColor = 'rgba(0, 255, 128, 0.3)';
//                         e.target.style.boxShadow = '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)';
//                         e.target.style.transform = 'translateY(0) scale(1)';
//                         e.target.style.letterSpacing = '0.5px';
//                       }}
//                     >
//                       <span style={{ 
//                         position: 'relative',
//                         zIndex: 1,
//                         textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
//                         display: 'inline-block'
//                       }}>
//                         {showOTP ? 'Login' : 'Get OTP'}
//                       </span>
//                     </button>
//                   </div>

//                   <div className="text-center mt-3">
//                     <span className="text-white">Don't have an account? </span>
//                     <a href="/signup" className="text-white text-decoration-none" style={{ borderBottom: '1px solid white' }}>
//                       Sign up
//                     </a>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;




import React, { useState } from 'react';
import { useNavigate, Link} from 'react-router-dom';
import axios from 'axios';
import CreatorWerse from "../../assets/CreatorWerse.png";

// Custom Alert Component remains unchanged
const CustomAlert = ({ message, type }) => {
  if (!message) return null;
  
  return (
    <div 
      style={{
        position: 'fixed',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        padding: '12px 24px',
        borderRadius: '4px',
        background: type === 'error' ? 'rgba(220, 38, 38, 0.9)' : 'rgba(22, 163, 74, 0.9)',
        color: 'white',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        textAlign: 'center',
        maxWidth: '90%',
        width: 'auto'
      }}
    >
      {message}
    </div>
  );
};

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    emailOrMobile: '',
    userId: '',
    otp: ['', '', '', '', '', '']
  });
  const [errors, setErrors] = useState({});
  const [showOTP, setShowOTP] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [alert, setAlert] = useState({ message: '', type: 'success' });
  // Add loading states
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResending, setIsResending] = useState(false);

  // Rest of the existing functions remain unchanged
  const showAlert = (message, type = 'success') => {
    setAlert({ message, type });
    setTimeout(() => setAlert({ message: '', type: 'success' }), 3000);
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setErrors(prev => ({
      ...prev,
      [name]: ''
    }));
  };

  const handleOTPChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newOTP = [...formData.otp];
      newOTP[index] = value;
      setFormData(prev => ({
        ...prev,
        otp: newOTP
      }));

      if (value && index < 5) {
        const nextInput = document.getElementById(`otp-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }
  };

  const handleOTPPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    if (/^\d{6}$/.test(pastedData)) {
      const otpArray = pastedData.split('');
      setFormData(prev => ({
        ...prev,
        otp: otpArray
      }));
    }
  };

  const startResendTimer = () => {
    setResendTimer(30);
    const timer = setInterval(() => {
      setResendTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleResendOTP = async () => {
    if (resendTimer > 0 || isResending) return;

    setIsResending(true);
    try {
      const data = new FormData();
      data.append("action", "sendotp");
      data.append("email_mobile_userid", formData.emailOrMobile);

      const response = await axios({
        method: "post",
        url: "https://creatorwerse.pmpframe.com/api/login.php",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data) {
        showAlert("OTP resent successfully!");
        startResendTimer();
      }
    } catch (error) {
      console.error("Resend OTP Error:", error);
      showAlert("Failed to resend OTP. Please try again.", "error");
    } finally {
      setIsResending(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    const newErrors = {};

    if (!validateEmail(formData.emailOrMobile)) {
      newErrors.emailOrMobile = "Please enter a valid email";
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);

    try {
      const contactValue = formData.emailOrMobile;

      if (!showOTP) {
        const data = new FormData();
        data.append("action", "sendotp");
        data.append("email_mobile_userid", contactValue);

        const response = await axios({
          method: "post",
          url: "https://creatorwerse.pmpframe.com/api/login.php",
          data: data,
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data) {
          if (response.data.account_status === "inactive") {
            showAlert("Your account is not activated. Please check your email for activation instructions or contact support.", "error");
            return;
          }
          
          if (response.data.success) {
            setShowOTP(true);
            startResendTimer();
            showAlert("OTP sent successfully!");
          } else {
            showAlert(response.data.message || "Failed to send OTP. Please try again.", "error");
          }
        }
      } else {
        const otpValue = formData.otp.join("");

        if (otpValue.length !== 6) {
          setErrors({ otp: "Please enter a valid 6-digit OTP" });
          return;
        }

        const data = new FormData();
        data.append("action", "login");
        data.append("otp", otpValue);
        data.append("email_mobile_userid", contactValue);

        const response = await fetch(
          "https://creatorwerse.pmpframe.com/api/login.php",
          {
            method: "POST",
            body: data,
          }
        );

        const res = await response.json();
        console.log("Login Response:", res);
        
        if (res.account_status === "inactive") {
          showAlert("Your account is not activated. Please check your email for activation instructions or contact support.", "error");
          return;
        }
        
        if (res.success) {
          localStorage.setItem("user", JSON.stringify(res.userid));
          showAlert(res.message);
          setTimeout(() => {
            navigate("/reelscriptform");
          }, 1500);
        } else {
          showAlert(res.message || "Login failed. Please try again.", "error");
        }
      }
    } catch (error) {
      console.error("API Error:", error);
      showAlert("An error occurred. Please try again.", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Return statement with the same UI but updated button states
  return (
    <div style={{ 
      position: 'fixed',
      inset: 0,
      backgroundColor: 'black',
      overflow: 'auto'
    }}>
      <CustomAlert message={alert.message} type={alert.type} />
      <div className="container h-100">
        <div className="row justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
          <div className="col-12 col-md-6 px-3">
            <div className="card shadow" style={{ backgroundColor: 'black', border: 'none' }}>
              <div className="card-body p-4">
                <div 
                  className="text-center mb-4" 
                  style={{ 
                    backgroundColor: 'black',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '8px'
                  }}
                >
                  <Link to="/">
                    <img 
                      src={CreatorWerse} 
                      alt="Logo" 
                      className="mb-3" 
                      style={{ width: '150px', height: '100px', objectFit: 'contain' }}
                    />
                  </Link>
                  <h2 className="text-white">Login</h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label text-white">Email*</label>
                    <input
                      type="text"
                      className={`form-control ${errors.emailOrMobile ? 'is-invalid' : ''}`}
                      name="emailOrMobile"
                      value={formData.emailOrMobile}
                      onChange={handleInputChange}
                      placeholder="Enter email"
                      disabled={isSubmitting}
                      style={{
                        backgroundColor: 'black',
                        color: 'white',
                        border: '1px solid white'
                      }}
                    />
                    {errors.emailOrMobile && (
                      <div className="invalid-feedback text-white">{errors.emailOrMobile}</div>
                    )}
                  </div>

                  {showOTP && (
                    <div className="mb-3">
                      <label className="form-label text-white">Enter OTP*</label>
                      <div className="d-flex gap-2 justify-content-center">
                        {[0, 1, 2, 3, 4, 5].map((index) => (
                          <input
                            key={index}
                            id={`otp-${index}`}
                            type="text"
                            className={`form-control text-center ${errors.otp ? 'is-invalid' : ''}`}
                            style={{ 
                              width: '45px',
                              backgroundColor: 'black',
                              color: 'white',
                              border: '1px solid white'
                            }}
                            maxLength="1"
                            value={formData.otp[index]}
                            onChange={(e) => handleOTPChange(index, e.target.value)}
                            onPaste={handleOTPPaste}
                            disabled={isSubmitting}
                          />
                        ))}
                      </div>
                      {errors.otp && (
                        <div className="text-white text-center mt-2">{errors.otp}</div>
                      )}
                      <div className="text-center mt-3">
                        <button
                          type="button"
                          onClick={handleResendOTP}
                          className="btn btn-link text-white text-decoration-none"
                          disabled={resendTimer > 0 || isResending}
                        >
                          {resendTimer > 0 ? `Resend OTP in ${resendTimer}s` : (isResending ? 'Resending...' : 'Resend OTP')}
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="text-center mt-4">
                    <button 
                      type="submit" 
                      className="btn"
                      disabled={isSubmitting}
                      style={{
                        background: 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))',
                        color: 'white',
                        border: '1px solid rgba(0, 255, 128, 0.3)',
                        padding: '12px',
                        transition: 'all 0.4s ease',
                        boxShadow: '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)',
                        fontWeight: '600',
                        letterSpacing: '0.5px',
                        position: 'relative',
                        overflow: 'hidden',
                        borderRadius: '6px',
                        fontSize: '16px',
                        width: '200px',
                        opacity: isSubmitting ? 0.7 : 1,
                        cursor: isSubmitting ? 'not-allowed' : 'pointer'
                      }}
                      onMouseOver={(e) => {
                        if (!isSubmitting) {
                          e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.95), rgba(0, 200, 100, 0.95))';
                          e.target.style.borderColor = 'rgba(0, 255, 128, 0.8)';
                          e.target.style.boxShadow = '0 6px 25px rgba(0, 170, 85, 0.4), 0 0 30px rgba(0, 255, 128, 0.4)';
                          e.target.style.transform = 'translateY(-2px) scale(1.01)';
                          e.target.style.letterSpacing = '1px';
                        }
                      }}
                      onMouseOut={(e) => {
                        if (!isSubmitting) {
                          e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))';
                          e.target.style.borderColor = 'rgba(0, 255, 128, 0.3)';
                          e.target.style.boxShadow = '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)';
                          e.target.style.transform = 'translateY(0) scale(1)';
                          e.target.style.letterSpacing = '0.5px';
                        }
                      }}
                    >
                      <span style={{ 
                        position: 'relative',
                        zIndex: 1,
                        textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
                        display: 'inline-block'
                      }}>
                        {isSubmitting ? (showOTP ? 'Logging in...' : 'Sending OTP...') : (showOTP ? 'Login' : 'Get OTP')}
                      </span>
                    </button>
                  </div>
                  <div className="text-center mt-3">
                    <span className="text-white">Don't have an account? </span>
                    <a href="/signup" className="text-white text-decoration-none" style={{ borderBottom: '1px solid white' }}>
                      Sign up
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;