// import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Home from "./pages/Home";
// import ContentSurvey from "./pages/ContentSurvey";
// import  TestimonialSection from "./pages/TestimonialSection";
// import MembershipCard from "./pages/MembershipCard";
// import EventLandingPage from "./pages/EventLandingPage";
// import LandingPage from "./pages/LandingPage";
// import Footer  from "./pages/Footer";
// import Banner from "./pages/Banner";





// const App = () => {
//     return (
//         <div>
//             <Home />
//             <EventLandingPage />
//             <ContentSurvey />
//             <TestimonialSection />
//             <MembershipCard />
//             <LandingPage />
//             <Footer />
//             <Banner />
             
//         </div>
//     );
// };

// export default App;





// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import SignUp from './components/SignUp/SignUp';
// import Login from './components/Login/Login';
// import Home from "./components/Home";
// import 'bootstrap/dist/css/bootstrap.min.css';

// // Add a Protected Route wrapper
// const ProtectedRoute = ({ element }) => {
//   const user = localStorage.getItem('user');
//   return user ? element : <Navigate to="/login" replace />;
// };

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Navigate to="/signup" replace />} />
//         <Route path="/signup" element={<SignUp />} />
//         <Route path="/login" element={<Login />} />
//         <Route 
//           path="/home" 
//           element={<ProtectedRoute element={<Home />} />}
//         />
//       </Routes>
//     </Router>
//   );
// }

// export default App;






// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';

// // Import authentication components


// // Import page components
// import Home from './pages/Home';
// import ContentSurvey from './pages/ContentSurvey';
// import TestimonialSection from './pages/TestimonialSection';
// import MembershipCard from './pages/MembershipCard';
// import EventLandingPage from './pages/EventLandingPage';
// import LandingPage from './pages/LandingPage';
// import Footer from './pages/Footer';
// import Banner from './pages/Banner';
// import SignUp from './components/SignUp/SignUp';
// import Login from './components/Login/Login';

// // Protected Route wrapper component
// const ProtectedRoute = ({ element }) => {
//   const user = localStorage.getItem('user');
//   return user ? element : <Navigate to="/login" replace />;
// };

// // Main content component that contains all the sections
// const MainContent = () => {
//   return (
 


// <Router>
//       <Routes>
//         {/* Default redirect to signup */}
//         {/* <Route path="/" element={<Navigate to="/signup" replace />} /> */}
        
//         {/* Authentication routes */}
//         {/* <Route path="/" element={<Home />} /> */}
//         <Route path="/signup" element={<SignUp />} />
//         <Route path="/login" element={<Login />} />
        
//         {/* Protected main content route */}
//         <Route 
//           path="/home" 
//           element={<ProtectedRoute element={<MainContent />} />} 
//         />
//       </Routes>
//     </Router>
//   );
// };

// // Combined App component
// const App = () => {
//   return (
//     <div>
     
//     <Home />
//     <EventLandingPage />
//     <ContentSurvey />
//     <TestimonialSection />
//     <MembershipCard />
//     <LandingPage />
//     <Footer />
//     <Banner />
//   </div>
    
//   );
// };

// export default App;


import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import SignUp from './components/SignUp/SignUp';
import Login from './components/Login/Login';
import ReelScriptsForm from './components/ReelScriptsForm';
import RoughPage from './components/RoughPage'

// Protected Route wrapper component
// const ProtectedRoute = ({ element }) => {
//   const user = localStorage.getItem('user');
//   return user ? element : <Navigate to="/login" replace />;
// };


// const ProtectedRoute = ({ element }) => {
//   const user = localStorage.getItem('user');
  
//   // Check if 'user' exists and is not an empty string
//   if (user && user.trim()) {
//     return <Navigate to="/login" replace />;

//   }
//   return element;

// };

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        {/* <Route path="/" element={<ProtectedRoute element={<Home />} />} /> */}

        <Route path="/" element={<Home />}  />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reelscriptform" element={<ReelScriptsForm />} />
        <Route path="/rough" element={<RoughPage />} />
         
        
        {/* Protected route */}
        {/* <Route 
          path="/home" 
          element={<ProtectedRoute element={<Home />} />} 
        /> */}
      </Routes>
    </Router>
  );
};

export default App;

