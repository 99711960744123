import React from 'react';

const styles = `
.copyright-banner {
  background: linear-gradient(45deg, #1a1a1a, #2d2d2d);
  transition: all 0.3s ease;
}

.copyright-text {
  letter-spacing: 0.5px;
}

.brand-text {
  background: linear-gradient(45deg, #ffc107, #fd7e14);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
`;

const Banner = () => {
  return (
    <>
      <style>{styles}</style>
      <div className="copyright-banner py-3">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p className="mb-0 copyright-text text-light">
                © {new Date().getFullYear()}. <span className="brand-text fw-bold">Creator Werse</span>. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;