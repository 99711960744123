import React, { useEffect } from 'react';
import { Play, Users, Video, Calendar } from 'lucide-react';

const EventLandingPage = () => {
  useEffect(() => {
    // Add animation classes after component mount
    const elements = document.querySelectorAll('.fade-in');
    elements.forEach((el, index) => {
      setTimeout(() => {
        el.style.opacity = '1';
        el.style.transform = 'translateY(0)';
      }, index * 200);
    });
  }, []);

  const StatsCard = ({ icon: Icon, count, label }) => (
    <div className="col-4">
      <div className="p-3 text-center rounded-4 bg-opacity-10 bg-white h-100" 
           style={{ backdropFilter: 'blur(10px)', transition: 'transform 0.3s ease' }}>
        <div className="d-flex justify-content-center align-items-center mb-3" style={{ height: '40px' }}>
          <Icon size={24} className="text-primary" />
        </div>
        <h3 className="h4 mb-1">{count}</h3>
        <p className="small text-light-emphasis">{label}</p>
      </div>
    </div>
  );

  return (
    <div style={{ 
      background: 'linear-gradient(135deg, #1a1a1a, #000000, #1a1a1a)',
      minHeight: '100vh',
      color: 'white'
    }}>
      <div className="container py-5">
        {/* Header */}
        <div className="row mb-5 text-center fade-in">
          <div className="col-12">
            <h1 className="display-4 fw-bold mb-3" style={{
              background: 'linear-gradient(45deg, #a855f7, #ec4899, #ef4444)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              color: 'transparent'
            }}>
              The Ultimate Creator Pad for Visionary Leaders
            </h1>
            <p className="lead" style={{
              background: 'linear-gradient(90deg, #64B5F6, #81C784, #FFB74D)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              color: 'transparent',
              fontSize: '1.5rem',
              fontWeight: '500',
              letterSpacing: '0.5px',
              textAlign: 'center',
              margin: '1.5rem 0',
              padding: '0.5rem',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
              animation: 'shimmer 2s infinite linear',
            }}>
              who want to leave behind a legacy
            </p>
            <style>
              {`
                @keyframes shimmer {
                  0% {
                    background-position: -200% center;
                  }
                  100% {
                    background-position: 200% center;
                  }
                }
              `}
            </style>
          </div>
        </div>

        {/* Main Content */}
        <div className="row g-4 align-items-start justify-content-center">
          {/* Video Section */}
          <div className="col-lg-7 fade-in">
            <div className="rounded-4 overflow-hidden" style={{
              border: '2px solid rgba(168, 85, 247, 0.3)',
              boxShadow: '0 0 30px rgba(168, 85, 247, 0.2)'
            }}>
              <div style={{ position: 'relative', paddingBottom: '62.25%', height: 0 }}>
                <iframe
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 0
                  }}
                  src="https://www.youtube-nocookie.com/embed/lzabPeIAWUs?controls=0&rel=0&playsinline=1&modestbranding=0&autoplay=1&mute=1&loop=1&playlist=lzabPeIAWUs"
                  title="CreatorWerse Bootcamp"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>

          {/* Content Section */}
          <div className="col-lg-5 fade-in">
            <div className="p-4 rounded-4" style={{
              background: 'rgba(31, 41, 55, 0.5)',
              backdropFilter: 'blur(10px)',
              border: '1px solid rgba(168, 85, 247, 0.2)'
            }}>
              <h2 className="text-center h2 mb-4" style={{
                background: 'linear-gradient(45deg, #a855f7, #ec4899, #ef4444)',
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                color: 'transparent'
              }}>
                Book Your Tickets Now!
              </h2>

              {/* Stats */}
              <div className="row g-3 mb-4">
                <StatsCard icon={Play} count="110+" label="Live Events" />
                <StatsCard icon={Users} count="1 Lakh+" label="Students Trained" />
                <StatsCard icon={Video} count="1,500+" label="Webinars" />
              </div>

              {/* Event Date */}
              <div className="text-center mb-4">
                <div className="d-inline-flex align-items-center py-2 px-4 rounded-pill bg-primary bg-opacity-10">
                  <Calendar size={20} className="me-2 text-info" />
                  <span className="text-info">LIVE IN Hyderabad | Dec 6-8th, 2024</span>
                </div>
              </div>

              {/* CTA Button */}
              <button className="btn w-100 py-3 mb-3 fw-bold text-dark" 
                      style={{
                        background: 'linear-gradient(45deg, #4ade80, #10b981)',
                        border: 'none',
                        transition: 'transform 0.3s ease, filter 0.3s ease'
                      }}
                      onMouseOver={e => {
                        e.currentTarget.style.transform = 'scale(1.05)';
                        e.currentTarget.style.filter = 'brightness(1.1)';
                      }}
                      onMouseOut={e => {
                        e.currentTarget.style.transform = 'scale(1)';
                        e.currentTarget.style.filter = 'brightness(1)';
                      }}>
                Yes! I Want To Attend
              </button>

              <p className="text-center text-light-emphasis small">
                The CreatorWerse Video Creation Bootcamp
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventLandingPage;