
// import React, { useState } from 'react';
// import CreatorWerse  from "../assets/CreatorWerse.png";
// import 'bootstrap/dist/css/bootstrap.min.css';

// const ReelScriptsForm = () => {
//   const [formData, setFormData] = useState({
//     product: '',
//     targetAudience: '',
//     results: '',
//     businessName: '',
//     name: ''
//   });
  
//   const [needApproval, setNeedApproval] = useState(false);
  
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };
  
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Form submitted:', formData);
//   };

//   return (
//     <div className="container-fluid bg-light py-5">
//       <div className="row justify-content-center">
//         <div className="col-md-6 col-lg-4">
//           {/* Logo */}
//           <div className="text-center mb-4">
//           <div className="d-inline-block p-3" style={{ backgroundColor: 'black' }}>
//               <img 
//                 src={CreatorWerse}
//                 alt="Logo" 
//                 className="img-fluid"
//                 style={{ height: '100px', width: '100px' }}
//               />
//             </div>
//           </div>
          
//           {/* Form Card */}
//           <div className="card shadow-sm">
//             <div className="card-body p-4">
//               <h2 className="card-title h4 mb-4">Reel scripts - 1</h2>
              
//               <form onSubmit={handleSubmit}>
//                 <div className="mb-3">
//                   <label className="form-label">
//                     write your Favourite product/service name?
//                     <span className="text-danger">*</span>
//                   </label>
//                   <input
//                     type="text"
//                     name="product"
//                     value={formData.product}
//                     onChange={handleInputChange}
//                     className="form-control bg-light"
//                     placeholder="Type here..."
//                     required
//                   />
//                 </div>

//                 <div className="mb-3">
//                   <label className="form-label">
//                     Who is your target audience?
//                     <span className="text-danger">*</span>
//                   </label>
//                   <input
//                     type="text"
//                     name="targetAudience"
//                     value={formData.targetAudience}
//                     onChange={handleInputChange}
//                     className="form-control bg-light"
//                     placeholder="Type here..."
//                     required
//                   />
//                 </div>

//                 <div className="mb-3">
//                   <label className="form-label">
//                     What results you provide for favourite product?
//                     <span className="text-danger">*</span>
//                   </label>
//                   <input
//                     type="text"
//                     name="results"
//                     value={formData.results}
//                     onChange={handleInputChange}
//                     className="form-control bg-light"
//                     placeholder="Type here..."
//                     required
//                   />
//                 </div>

//                 <div className="mb-3">
//                   <label className="form-label">
//                     Your Business name
//                     <span className="text-danger">*</span>
//                   </label>
//                   <input
//                     type="text"
//                     name="businessName"
//                     value={formData.businessName}
//                     onChange={handleInputChange}
//                     className="form-control bg-light"
//                     placeholder="Type here..."
//                     required
//                   />
//                 </div>

//                 <div className="mb-3">
//                   <label className="form-label">
//                     your name
//                     <span className="text-danger">*</span>
//                   </label>
//                   <input
//                     type="text"
//                     name="name"
//                     value={formData.name}
//                     onChange={handleInputChange}
//                     className="form-control bg-light"
//                     placeholder="Type here..."
//                     required
//                   />
//                 </div>

//                 <div className="mb-3 form-check form-switch">
//                   <input
//                     type="checkbox"
//                     className="form-check-input"
//                     id="approvalToggle"
//                     checked={needApproval}
//                     onChange={(e) => setNeedApproval(e.target.checked)}
//                   />
//                   <label className="form-check-label" htmlFor="approvalToggle">
//                     Ask for human approval before each step
//                   </label>
//                 </div>

              
//                 <button
//                   type="submit"
//                   className="btn w-100 text-white"
//                   style={{ backgroundColor: 'black' }}
//                 >
//                   Submit
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReelScriptsForm;




// import React, { useState } from 'react';
// import CreatorWerse from "../assets/CreatorWerse.png";
// import 'bootstrap/dist/css/bootstrap.min.css';

// const ReelScriptsForm = () => {
//   const [formData, setFormData] = useState({
//     product: '',
//     targetAudience: '',
//     results: '',
//     businessName: '',
//     name: ''
//   });
  
//   const [needApproval, setNeedApproval] = useState(false);
  
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };
  
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Form submitted:', formData);
//   };

//   return (
//     <div style={{ backgroundColor: 'black', minHeight: '100vh' }}>
//       <div className="container-fluid py-5">
//         <div className="row justify-content-center">
//           <div className="col-md-6 col-lg-4">
//             {/* Logo */}
//             <div className="text-center mb-4">
//               <img 
//                 src={CreatorWerse}
//                 alt="Logo" 
//                 className="img-fluid"
//                 style={{ height: '100px', width: '100px' }}
//               />
//             </div>
            
//             {/* Form Card */}
//             <div className="card shadow-sm">
//               <div className="card-body p-4">
//                 <h2 className="card-title h4 mb-4">Reel scripts - 1</h2>
                
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label className="form-label">
//                       write your Favourite product/service name?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="product"
//                       value={formData.product}
//                       onChange={handleInputChange}
//                       className="form-control bg-light"
//                       placeholder="Type here..."
//                       required
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label className="form-label">
//                       Who is your target audience?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="targetAudience"
//                       value={formData.targetAudience}
//                       onChange={handleInputChange}
//                       className="form-control bg-light"
//                       placeholder="Type here..."
//                       required
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label className="form-label">
//                       What results you provide for favourite product?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="results"
//                       value={formData.results}
//                       onChange={handleInputChange}
//                       className="form-control bg-light"
//                       placeholder="Type here..."
//                       required
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label className="form-label">
//                       Your Business name
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="businessName"
//                       value={formData.businessName}
//                       onChange={handleInputChange}
//                       className="form-control bg-light"
//                       placeholder="Type here..."
//                       required
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label className="form-label">
//                       your name
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleInputChange}
//                       className="form-control bg-light"
//                       placeholder="Type here..."
//                       required
//                     />
//                   </div>

//                   <div className="mb-3 form-check form-switch">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="approvalToggle"
//                       checked={needApproval}
//                       onChange={(e) => setNeedApproval(e.target.checked)}
//                     />
//                     <label className="form-check-label" htmlFor="approvalToggle">
//                       Ask for human approval before each step
//                     </label>
//                   </div>

//                   <button
//                     type="submit"
//                     className="btn w-100 text-white"
//                     style={{ backgroundColor: 'black' }}
//                   >
//                     Submit
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReelScriptsForm;

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import CreatorWerse from "../assets/CreatorWerse.png";
// import 'bootstrap/dist/css/bootstrap.min.css';

// const ReelScriptsForm = () => {
//   const navigate = useNavigate();
  
//   // Default form data
//   const defaultFormData = {
//     product: 'Premium Coffee Beans',
//     targetAudience: 'Coffee enthusiasts and home brewers',
//     results: 'Perfect morning brew, enhanced productivity',
//     businessName: 'Morning Bliss Coffee Co.',
//     name: 'John Smith'
//   };

//   const [formData, setFormData] = useState(defaultFormData);
//   const [needApproval, setNeedApproval] = useState(false);
  
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };
  
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log('Form submitted:', formData);
    
//     // Navigate to rough page with form data
//     navigate('/rough', { 
//       state: { 
//         ...formData, 
//         needApproval 
//       } 
//     });
//   };

//   // Reset form to default values
//   const resetToDefault = () => {
//     setFormData(defaultFormData);
//     setNeedApproval(false);
//   };

//   return (
//     <div style={{ backgroundColor: 'black', minHeight: '100vh' }}>
//       <div className="container-fluid py-5">
//         <div className="row justify-content-center">
//           <div className="col-md-6 col-lg-4">
//             {/* Logo */}
//             <div className="text-center mb-4">
//               <img 
//                 src={CreatorWerse}
//                 alt="Logo" 
//                 className="img-fluid"
//                 style={{ height: '100px', width: '100px' }}
//               />
//             </div>
            
//             {/* Form Card */}
//             <div className="card shadow-sm">
//               <div className="card-body p-4">
//                 <h2 className="card-title h4 mb-4">Reel scripts - 1</h2>
                
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label className="form-label">
//                       write your Favourite product/service name?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="product"
//                       value={formData.product}
//                       onChange={handleInputChange}
//                       className="form-control bg-light"
//                       placeholder="Type here..."
//                       required
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label className="form-label">
//                       Who is your target audience?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="targetAudience"
//                       value={formData.targetAudience}
//                       onChange={handleInputChange}
//                       className="form-control bg-light"
//                       placeholder="Type here..."
//                       required
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label className="form-label">
//                       What results you provide for favourite product?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="results"
//                       value={formData.results}
//                       onChange={handleInputChange}
//                       className="form-control bg-light"
//                       placeholder="Type here..."
//                       required
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label className="form-label">
//                       Your Business name
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="businessName"
//                       value={formData.businessName}
//                       onChange={handleInputChange}
//                       className="form-control bg-light"
//                       placeholder="Type here..."
//                       required
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label className="form-label">
//                       your name
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleInputChange}
//                       className="form-control bg-light"
//                       placeholder="Type here..."
//                       required
//                     />
//                   </div>

//                   <div className="mb-3 form-check form-switch">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="approvalToggle"
//                       checked={needApproval}
//                       onChange={(e) => setNeedApproval(e.target.checked)}
//                     />
//                     <label className="form-check-label" htmlFor="approvalToggle">
//                       Ask for human approval before each step
//                     </label>
//                   </div>

//                   <div className="d-grid gap-2">
//                     <button
//                       type="submit"
//                       className="btn text-white"
//                       style={{ backgroundColor: 'black' }}
//                     >
//                       Submit
//                     </button>
//                     <button
//                       type="button"
//                       className="btn btn-outline-secondary"
//                       onClick={resetToDefault}
//                     >
//                       Reset to Default
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReelScriptsForm;



// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import CreatorWerse from "../assets/CreatorWerse.png";
// import 'bootstrap/dist/css/bootstrap.min.css';

// const ReelScriptsForm = () => {
//   const navigate = useNavigate();
  
//   // Default form data
//   const defaultFormData = {
//     product: 'Premium Coffee Beans',
//     targetAudience: 'Coffee enthusiasts and home brewers',
//     results: 'Perfect morning brew, enhanced productivity',
//     businessName: 'Morning Bliss Coffee Co.',
//     name: 'John Smith'
//   };

//   const [formData, setFormData] = useState(defaultFormData);
//   const [needApproval, setNeedApproval] = useState(false);
  
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };
  
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     navigate('/rough', { 
//       state: { 
//         ...formData, 
//         needApproval 
//       } 
//     });
//   };

//   const resetToDefault = () => {
//     setFormData(defaultFormData);
//     setNeedApproval(false);
//   };

//   // Custom styles for black and white theme
//   const styles = {
//     input: {
//       backgroundColor: 'black',
//       color: 'white',
//       border: '1px solid white'
//     },
//     label: {
//       color: 'white'
//     },
//     card: {
//       backgroundColor: 'black',
//       border: '1px solid white'
//     },
//     cardTitle: {
//       color: 'white'
//     }
//   };

//   return (
//     <div style={{ backgroundColor: 'black', minHeight: '100vh' }}>
//       <div className="container-fluid py-5">
//         <div className="row justify-content-center">
//           <div className="col-md-6 col-lg-4">
//             {/* Logo */}
//             <div className="text-center mb-4">
//               <img 
//                 src={CreatorWerse}
//                 alt="Logo" 
//                 className="img-fluid"
//                 style={{ height: '100px', width: '100px' }}
//               />
//             </div>
            
//             {/* Form Card */}
//             <div className="card shadow-sm" style={styles.card}>
//               <div className="card-body p-4">
//                 <h2 className="card-title h4 mb-4" style={styles.cardTitle}>Reel scripts - 1</h2>
                
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label className="form-label" style={styles.label}>
//                       write your Favourite product/service name?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="product"
//                       value={formData.product}
//                       onChange={handleInputChange}
//                       className="form-control"
//                       placeholder="Type here..."
//                       required
//                       style={styles.input}
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label className="form-label" style={styles.label}>
//                       Who is your target audience?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="targetAudience"
//                       value={formData.targetAudience}
//                       onChange={handleInputChange}
//                       className="form-control"
//                       placeholder="Type here..."
//                       required
//                       style={styles.input}
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label className="form-label" style={styles.label}>
//                       What results you provide for favourite product?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="results"
//                       value={formData.results}
//                       onChange={handleInputChange}
//                       className="form-control"
//                       placeholder="Type here..."
//                       required
//                       style={styles.input}
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label className="form-label" style={styles.label}>
//                       Your Business name
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="businessName"
//                       value={formData.businessName}
//                       onChange={handleInputChange}
//                       className="form-control"
//                       placeholder="Type here..."
//                       required
//                       style={styles.input}
//                     />
//                   </div>

//                   <div className="mb-3">
//                     <label className="form-label" style={styles.label}>
//                       your name
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleInputChange}
//                       className="form-control"
//                       placeholder="Type here..."
//                       required
//                       style={styles.input}
//                     />
//                   </div>

//                   <div className="mb-3 form-check form-switch">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="approvalToggle"
//                       checked={needApproval}
//                       onChange={(e) => setNeedApproval(e.target.checked)}
//                       style={{ backgroundColor: needApproval ? 'black' : 'white', borderColor: 'white' }}
//                     />
//                     <label className="form-check-label" htmlFor="approvalToggle" style={styles.label}>
//                       Ask for human approval before each step
//                     </label>
//                   </div>

//                   <div className="d-grid gap-2">
//                     <button
//                       type="submit"
//                       className="btn"
//                       style={{
//                         background: 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))',
//                         color: 'white',
//                         border: '1px solid rgba(0, 255, 128, 0.3)',
//                         padding: '12px',
//                         transition: 'all 0.4s ease',
//                         boxShadow: '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)',
//                         fontWeight: '600',
//                         letterSpacing: '0.5px',
//                         position: 'relative',
//                         overflow: 'hidden',
//                         borderRadius: '6px',
//                         fontSize: '16px',
//                       }}
//                       onMouseOver={(e) => {
//                         e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.95), rgba(0, 200, 100, 0.95))';
//                         e.target.style.borderColor = 'rgba(0, 255, 128, 0.8)';
//                         e.target.style.boxShadow = '0 6px 25px rgba(0, 170, 85, 0.4), 0 0 30px rgba(0, 255, 128, 0.4)';
//                         e.target.style.transform = 'translateY(-2px) scale(1.01)';
//                         e.target.style.letterSpacing = '1px';
//                       }}
//                       onMouseOut={(e) => {
//                         e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))';
//                         e.target.style.borderColor = 'rgba(0, 255, 128, 0.3)';
//                         e.target.style.boxShadow = '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)';
//                         e.target.style.transform = 'translateY(0) scale(1)';
//                         e.target.style.letterSpacing = '0.5px';
//                       }}
//                     >
//                       Submit
//                     </button>
//                     <button
//                       type="button"
//                       className="btn btn-outline-light"
//                       onClick={resetToDefault}
//                     >
//                       Reset to Default
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReelScriptsForm;

// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import CreatorWerse from "../assets/CreatorWerse.png";
// import 'bootstrap/dist/css/bootstrap.min.css';

// const ReelScriptsForm = () => {
//   const navigate = useNavigate();

//   // Default form data
//   const defaultFormData = {
//     product: 'Premium Coffee Beans',
//     targetAudience: 'Coffee enthusiasts and home brewers',
//     results: 'Perfect morning brew, enhanced productivity',
//     businessName: 'Morning Bliss Coffee Co.'
//   };

//   const [formData, setFormData] = useState(defaultFormData);
//   const [needApproval, setNeedApproval] = useState(false);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     navigate('/rough', {
//       state: {
//         ...formData,
//         needApproval
//       }
//     });
//   };

//   const resetToDefault = () => {
//     setFormData(defaultFormData);
//     setNeedApproval(false);
//   };

//   // Custom styles for black and white theme
//   const styles = {
//     input: {
//       backgroundColor: 'black',
//       color: 'white',
//       border: '1px solid #555',
//       fontSize: '18px',
//       padding: '12px'
//     },
//     label: {
//       color: 'white',
//       fontSize: '16px'
//     },
//     card: {
//       backgroundColor: 'black',
//       border: 'none',
//       width: '80%',
//       marginLeft: 'auto',
//       marginRight: 'auto'
//     },
//     cardTitle: {
//       color: 'white',
//       fontSize: '24px'
//     }
//   };

//   return (
//     <div style={{ backgroundColor: 'black', minHeight: '100vh' }}>
//       <div className="container-fluid py-5">
//         <div className="row justify-content-center">
//           <div className="col-md-8 col-lg-6">
//             {/* Logo */}
//             <div className="text-center mb-4">
//               <img
//                 src={CreatorWerse}
//                 alt="Logo"
//                 className="img-fluid"
//                 style={{ height: '100px', width: '100px' }}
//               />
//             </div>

//             {/* Form Card */}
//             <div className="card shadow-sm" style={styles.card}>
//               <div className="card-body p-5">
//                 <h2 className="card-title h4 mb-4" style={styles.cardTitle}>Reel scripts - 1</h2>

//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-4">
//                     <label className="form-label" style={styles.label}>
//                       write your Favourite product/service name?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="product"
//                       value={formData.product}
//                       onChange={handleInputChange}
//                       className="form-control"
//                       placeholder="Type here..."
//                       required
//                       style={styles.input}
//                     />
//                   </div>

//                   <div className="mb-4">
//                     <label className="form-label" style={styles.label}>
//                       Who is your target audience?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="targetAudience"
//                       value={formData.targetAudience}
//                       onChange={handleInputChange}
//                       className="form-control"
//                       placeholder="Type here..."
//                       required
//                       style={styles.input}
//                     />
//                   </div>

//                   <div className="mb-4">
//                     <label className="form-label" style={styles.label}>
//                       What results you provide for favourite product?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="results"
//                       value={formData.results}
//                       onChange={handleInputChange}
//                       className="form-control"
//                       placeholder="Type here..."
//                       required
//                       style={styles.input}
//                     />
//                   </div>

//                   <div className="mb-4">
//                     <label className="form-label" style={styles.label}>
//                       Your Business name
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="businessName"
//                       value={formData.businessName}
//                       onChange={handleInputChange}
//                       className="form-control"
//                       placeholder="Type here..."
//                       required
//                       style={styles.input}
//                     />
//                   </div>

//                   <div className="mb-4 form-check form-switch">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="approvalToggle"
//                       checked={needApproval}
//                       onChange={(e) => setNeedApproval(e.target.checked)}
//                       style={{ backgroundColor: needApproval ? 'black' : 'white', borderColor: 'white' }}
//                     />
//                     <label className="form-check-label" htmlFor="approvalToggle" style={styles.label}>
//                       Ask for human approval before each step
//                     </label>
//                   </div>

//                   <div className="d-grid gap-2">
//                     <button
//                       type="submit"
//                       className="btn"
//                       style={{
//                         background: 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))',
//                         color: 'white',
//                         border: 'none',
//                         padding: '14px',
//                         transition: 'all 0.4s ease',
//                         boxShadow: '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)',
//                         fontWeight: '600',
//                         letterSpacing: '0.5px',
//                         position: 'relative',
//                         overflow: 'hidden',
//                         borderRadius: '6px',
//                         fontSize: '18px',
//                       }}
//                       onMouseOver={(e) => {
//                         e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.95), rgba(0, 200, 100, 0.95))';
//                         e.target.style.boxShadow = '0 6px 25px rgba(0, 170, 85, 0.4), 0 0 30px rgba(0, 255, 128, 0.4)';
//                         e.target.style.transform = 'translateY(-2px) scale(1.01)';
//                         e.target.style.letterSpacing = '1px';
//                       }}
//                       onMouseOut={(e) => {
//                         e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))';
//                         e.target.style.boxShadow = '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)';
//                         e.target.style.transform = 'translateY(0) scale(1)';
//                         e.target.style.letterSpacing = '0.5px';
//                       }}
//                     >
//                       Submit
//                     </button>
//                     <button
//                       type="button"
//                       className="btn btn-outline-light"
//                       onClick={resetToDefault}
//                       style={{
//                         fontSize: '18px'
//                       }}
//                     >
//                       Reset to Default
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReelScriptsForm;





// import React, { useState } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import CreatorWerse from "../assets/CreatorWerse.png";
// import 'bootstrap/dist/css/bootstrap.min.css';

// const ReelScriptsForm = () => {
//   const navigate = useNavigate();

//   // Default form data
//   const defaultFormData = {
//     product: 'Premium Coffee Beans',
//     targetAudience: 'Coffee enthusiasts and home brewers',
//     results: 'Perfect morning brew, enhanced productivity',
//     businessName: 'Morning Bliss Coffee Co.'
//   };

//   const [formData, setFormData] = useState(defaultFormData);
//   const [needApproval, setNeedApproval] = useState(false);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     navigate('/rough', {
//       state: {
//         ...formData,
//         needApproval
//       }
//     });
//   };

//   const resetToDefault = () => {
//     setFormData(defaultFormData);
//     setNeedApproval(false);
//   };

//   const handleLogout = () => {
//     localStorage.clear();
//     navigate('/');
//   };

//   // Custom styles for black and white theme
//   const styles = {
//     input: {
//       backgroundColor: 'black',
//       color: 'white',
//       border: '1px solid #555',
//       fontSize: '18px',
//       padding: '12px'
//     },
//     label: {
//       color: 'white',
//       fontSize: '16px'
//     },
//     card: {
//       backgroundColor: 'black',
//       border: 'none',
//       width: '80%',
//       marginLeft: 'auto',
//       marginRight: 'auto'
//     },
//     cardTitle: {
//       color: 'white',
//       fontSize: '24px'
//     },
//     logoutButton: {
//       position: 'fixed',
//       top: '20px',
//       right: '20px',
//       zIndex: 1000,
//       padding: '8px 20px',
//       fontSize: '16px',
//       fontWeight: '600',
//       cursor: 'pointer',
     
//     }
//   };

//   return (
//     <div style={{ backgroundColor: 'black', minHeight: '100vh' }}>
    
//       <button
        

//         className="btn btn-outline-warning btn-lg"
//         style={styles.logoutButton}
//         onClick={handleLogout}
      
//       >
//         Logout
//       </button>

//       <div className="container-fluid py-5">
//         {/* Rest of the existing code remains exactly the same */}
//         <div className="row justify-content-center">
//           <div className="col-md-8 col-lg-6">
//             {/* Logo */}
//             <div className="text-center mb-4">
//             <Link to="/">
//           <img 
//           src={CreatorWerse} 
//           alt="Logo" 
//           className="mb-3" 
//           style={{ width: '150px', height: '100px', objectFit: 'contain' }}
//         />
//       </Link>

//             </div>

//             {/* Form Card */}
//             <div className="card shadow-sm" style={styles.card}>
//               <div className="card-body p-5">
//                 <h2 className="card-title h4 mb-4" style={styles.cardTitle}>Reel scripts - 1</h2>

//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-4">
//                     <label className="form-label" style={styles.label}>
//                       write your Favourite product/service name?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="product"
//                       value={formData.product}
//                       onChange={handleInputChange}
//                       className="form-control"
//                       placeholder="Type here..."
//                       required
//                       style={styles.input}
//                     />
//                   </div>

//                   <div className="mb-4">
//                     <label className="form-label" style={styles.label}>
//                       Who is your target audience?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="targetAudience"
//                       value={formData.targetAudience}
//                       onChange={handleInputChange}
//                       className="form-control"
//                       placeholder="Type here..."
//                       required
//                       style={styles.input}
//                     />
//                   </div>

//                   <div className="mb-4">
//                     <label className="form-label" style={styles.label}>
//                       What results you provide for favourite product?
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="results"
//                       value={formData.results}
//                       onChange={handleInputChange}
//                       className="form-control"
//                       placeholder="Type here..."
//                       required
//                       style={styles.input}
//                     />
//                   </div>

//                   <div className="mb-4">
//                     <label className="form-label" style={styles.label}>
//                       Your Business name
//                       <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="businessName"
//                       value={formData.businessName}
//                       onChange={handleInputChange}
//                       className="form-control"
//                       placeholder="Type here..."
//                       required
//                       style={styles.input}
//                     />
//                   </div>

//                   <div className="mb-4 form-check form-switch">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="approvalToggle"
//                       checked={needApproval}
//                       onChange={(e) => setNeedApproval(e.target.checked)}
//                       style={{ backgroundColor: needApproval ? 'black' : 'white', borderColor: 'white' }}
//                     />
//                     <label className="form-check-label" htmlFor="approvalToggle" style={styles.label}>
//                       Ask for human approval before each step
//                     </label>
//                   </div>

//                   <div className="d-grid gap-2">
//                     <button
//                       type="submit"
//                       className="btn"
//                       style={{
//                         background: 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))',
//                         color: 'white',
//                         border: 'none',
//                         padding: '14px',
//                         transition: 'all 0.4s ease',
//                         boxShadow: '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)',
//                         fontWeight: '600',
//                         letterSpacing: '0.5px',
//                         position: 'relative',
//                         overflow: 'hidden',
//                         borderRadius: '6px',
//                         fontSize: '18px',
//                       }}
//                       onMouseOver={(e) => {
//                         e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.95), rgba(0, 200, 100, 0.95))';
//                         e.target.style.boxShadow = '0 6px 25px rgba(0, 170, 85, 0.4), 0 0 30px rgba(0, 255, 128, 0.4)';
//                         e.target.style.transform = 'translateY(-2px) scale(1.01)';
//                         e.target.style.letterSpacing = '1px';
//                       }}
//                       onMouseOut={(e) => {
//                         e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))';
//                         e.target.style.boxShadow = '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)';
//                         e.target.style.transform = 'translateY(0) scale(1)';
//                         e.target.style.letterSpacing = '0.5px';
//                       }}
//                     >
//                       Submit
//                     </button>
//                     <button
//                       type="button"
//                       className="btn btn-outline-light"
//                       onClick={resetToDefault}
//                       style={{
//                         fontSize: '18px'
//                       }}
//                     >
//                       Reset to Default
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ReelScriptsForm;



import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import CreatorWerse from "../assets/CreatorWerse.png";
import 'bootstrap/dist/css/bootstrap.min.css';

const ReelScriptsForm = () => {
  const navigate = useNavigate();
  const defaultFormData = {
    product: 'Premium Coffee Beans',
    targetAudience: 'Coffee enthusiasts and home brewers',
    results: 'Perfect morning brew, enhanced productivity',
    businessName: 'Morning Bliss Coffee Co.'
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [needApproval, setNeedApproval] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/rough', {
      state: {
        ...formData,
        needApproval
      }
    });
  };

  const resetToDefault = () => {
    setFormData(defaultFormData);
    setNeedApproval(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  const styles = {
    input: {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #555',
      fontSize: '16px',
      padding: '12px'
    },
    label: {
      color: 'white',
      fontSize: '16px'
    },
    card: {
      backgroundColor: 'black',
      border: 'none',
      width: '100%', // Changed from 80% to 100%
      margin: '0 auto'
    },
    cardTitle: {
      color: 'white',
      fontSize: '24px'
    },
    logoutButton: {
      width: 'auto',
      minWidth: '120px',
      padding: '8px 20px',
      fontSize: '16px',
      fontWeight: '600',
      cursor: 'pointer'
    },
    responsiveContainer: {
      padding: '20px',
      maxWidth: '100%'
    },
    logo: {
      width: 'auto',
      maxWidth: '150px',
      height: 'auto',
      maxHeight: '100px',
      objectFit: 'contain'
    }
  };

  // Media query styles for different screen sizes
  const mobileStyles = window.innerWidth <= 768 ? {
    logoutButton: {
      ...styles.logoutButton,
      position: 'relative',
      top: '0',
      right: '0',
      width: '100%',
      marginBottom: '20px'
    },
    card: {
      ...styles.card,
      padding: '10px'
    },
    input: {
      ...styles.input,
      fontSize: '14px'
    }
  } : styles;

  return (
    <div style={{ backgroundColor: 'black', minHeight: '100vh' }}>
      <div className="container-fluid" style={styles.responsiveContainer}>
        <div className="row">
          <div className="col-12 d-flex justify-content-end mb-4">
            <button
              className="btn btn-outline-warning"
              style={{
                width: 'auto',
                minWidth: '120px',
                padding: '8px 20px',
                fontSize: '16px',
                fontWeight: '600',
                marginRight: '15px'
              }}
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="text-center mb-4">
              <Link to="/">
                <img 
                  src={CreatorWerse} 
                  alt="Logo" 
                  className="img-fluid"
                  style={styles.logo}
                />
              </Link>
            </div>

            <div className="card shadow-sm" style={mobileStyles.card}>
              <div className="card-body p-3 p-md-5">
                <h2 className="card-title h4 mb-4" style={styles.cardTitle}>Reel scripts - 1</h2>

                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label className="form-label" style={styles.label}>
                      write your Favourite product/service name?
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="product"
                      value={formData.product}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Type here..."
                      required
                      style={mobileStyles.input}
                    />
                  </div>

                  <div className="mb-4">
                    <label className="form-label" style={styles.label}>
                      Who is your target audience?
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="targetAudience"
                      value={formData.targetAudience}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Type here..."
                      required
                      style={mobileStyles.input}
                    />
                  </div>

                  <div className="mb-4">
                    <label className="form-label" style={styles.label}>
                      What results you provide for favourite product?
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="results"
                      value={formData.results}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Type here..."
                      required
                      style={mobileStyles.input}
                    />
                  </div>

                  <div className="mb-4">
                    <label className="form-label" style={styles.label}>
                      Your Business name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="businessName"
                      value={formData.businessName}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Type here..."
                      required
                      style={mobileStyles.input}
                    />
                  </div>

                  <div className="mb-4 form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="approvalToggle"
                      checked={needApproval}
                      onChange={(e) => setNeedApproval(e.target.checked)}
                      style={{ backgroundColor: needApproval ? 'black' : 'white', borderColor: 'white' }}
                    />
                    <label className="form-check-label" htmlFor="approvalToggle" style={styles.label}>
                      Ask for human approval before each step
                    </label>
                  </div>

                  <div className="d-grid gap-2">
                    <button
                      type="submit"
                      className="btn"
                      style={{
                        background: 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))',
                        color: 'white',
                        border: 'none',
                        padding: '14px',
                        transition: 'all 0.4s ease',
                        boxShadow: '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)',
                        fontWeight: '600',
                        letterSpacing: '0.5px',
                        position: 'relative',
                        overflow: 'hidden',
                        borderRadius: '6px',
                        fontSize: '18px',
                      }}
                      onMouseOver={(e) => {
                        e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.95), rgba(0, 200, 100, 0.95))';
                        e.target.style.boxShadow = '0 6px 25px rgba(0, 170, 85, 0.4), 0 0 30px rgba(0, 255, 128, 0.4)';
                        e.target.style.transform = 'translateY(-2px) scale(1.01)';
                        e.target.style.letterSpacing = '1px';
                      }}
                      onMouseOut={(e) => {
                        e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))';
                        e.target.style.boxShadow = '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)';
                        e.target.style.transform = 'translateY(0) scale(1)';
                        e.target.style.letterSpacing = '0.5px';
                      }}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-light"
                      onClick={resetToDefault}
                      style={{
                        fontSize: '16px'
                      }}
                    >
                      Reset to Default
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReelScriptsForm;