
// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import CreatorWerse from "../../assets/CreatorWerse.png";
// import axios from 'axios';

// const SignUp = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     userId: '',
//     mobile: '',
//     email: '',
//     profession: ''
//   });

//   const [errors, setErrors] = useState({});
//   const [successMessage, setSuccessMessage] = useState('');
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const professions = [
//     'Software Developer',
//     'Doctor',
//     'Teacher',
//     'Engineer',
//     'Designer',
//     'Business Analyst',
//     'Other'
//   ];

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//     // Clear error when user starts typing
//     if (errors[name]) {
//       setErrors(prevErrors => ({
//         ...prevErrors,
//         [name]: ''
//       }));
//     }
//   };

//   const validateForm = () => {
//     const newErrors = {};

//     // First Name validation
//     if (!formData.firstName.trim()) {
//       newErrors.firstName = 'First name is required';
//     }

//     // Last Name validation
//     if (!formData.lastName.trim()) {
//       newErrors.lastName = 'Last name is required';
//     }

//     // User ID validation
//     if (!formData.userId.trim()) {
//       newErrors.userId = 'User ID is required';
//     } else if (formData.userId.length < 4) {
//       newErrors.userId = 'User ID must be at least 4 characters';
//     }

//     // Mobile validation
//     if (!formData.mobile) {
//       newErrors.mobile = 'Mobile number is required';
//     }

//     // Email validation
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!formData.email) {
//       newErrors.email = 'Email is required';
//     } else if (!emailRegex.test(formData.email)) {
//       newErrors.email = 'Please enter a valid email address';
//     }

//     // Profession validation
//     if (!formData.profession) {
//       newErrors.profession = 'Profession is required';
//     }

//     return newErrors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationErrors = validateForm();

//     if (Object.keys(validationErrors).length === 0) {
//       try {
//         setIsSubmitting(true);
//         // Create FormData object
//         const data = new FormData();
//         data.append('first_name', formData.firstName);
//         data.append('last_name', formData.lastName);
//         data.append('userid', formData.userId);
//         data.append('mobile', formData.mobile);
//         data.append('country_code', '+91');
//         data.append('email', formData.email);
//         data.append('profession', '1');
//         data.append('action', 'signup');

//         const config = {
//           method: 'post',
//           maxBodyLength: Infinity,
//           url: 'http://creatorwerse.pmpframe.com/api/signup.php',
//           headers: { 
//             'Content-Type': 'multipart/form-data'
//           },
//           data: data
//         };

//         const response = await axios.request(config);
//         console.log('API Response:', response.data);

//         if (response.data.success) {
//           // Clear form data
//           setFormData({
//             firstName: '',
//             lastName: '',
//             userId: '',
//             mobile: '',
//             email: '',
//             profession: ''
//           });
          
//           // Set success message
//           setSuccessMessage('Registered successfully!');
          
//           // Redirect after 2 seconds
//           setTimeout(() => {
//             navigate('/login');
//           }, 2000);
//         } else {
//           setErrors({
//             api: response.data.message || 'Signup failed. Please try again.'
//           });
//         }
//       } catch (error) {
//         console.error('API Error:', error);
//         setErrors({
//           api: 'An error occurred during signup. Please try again later.'
//         });
//       } finally {
//         setIsSubmitting(false);
//       }
//     } else {
//       setErrors(validationErrors);
//     }
//   };

//   return (
//     // <div className="container mt-5" style={{ backgroundColor: 'black', minHeight: '100vh' }} >
//     <div  classaName="container mt-5" style={{ backgroundColor: 'black', minHeight: '100vh' }} >
//       <div className="row justify-content-center">
//         <div className="col-md-6">
//           <div className="card">
//             <div className="card-body">
//               <div 
//                 className="text-center mb-4 py-4" 
//                 style={{ 
//                   backgroundColor: 'black',
//                   width: '100%',
//                   height: '200px',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   borderRadius: '8px'
//                 }}
//               >
//                 <img 
//                   src={CreatorWerse} 
//                   alt="Logo" 
//                   className="mb-3" 
//                   style={{ 
//                     width: '150px',
//                     height: '100px',
//                     objectFit: 'contain'
//                   }} 
//                 />
//                 <h2 className="text-white">SignUp</h2>
//               </div>

//               <form onSubmit={handleSubmit}>
//                 {errors.api && (
//                   <div className="alert alert-danger" role="alert">
//                     {errors.api}
//                   </div>
//                 )}

//                 {successMessage && (
//                   <div className="alert alert-success" role="alert">
//                     {successMessage}
//                   </div>
//                 )}

//                 {/* First Name */}
//                 <div className="mb-3">
//                   <label htmlFor="firstName" className="form-label">First Name*</label>
//                   <input
//                     type="text"
//                     className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
//                     id="firstName"
//                     name="firstName"
//                     value={formData.firstName}
//                     onChange={handleChange}
//                   />
//                   {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
//                 </div>

//                 {/* Last Name */}
//                 <div className="mb-3">
//                   <label htmlFor="lastName" className="form-label">Last Name*</label>
//                   <input
//                     type="text"
//                     className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
//                     id="lastName"
//                     name="lastName"
//                     value={formData.lastName}
//                     onChange={handleChange}
//                   />
//                   {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
//                 </div>

//                 {/* User ID */}
//                 <div className="mb-3">
//                   <label htmlFor="userId" className="form-label">User ID*</label>
//                   <input
//                     type="text"
//                     className={`form-control ${errors.userId ? 'is-invalid' : ''}`}
//                     id="userId"
//                     name="userId"
//                     value={formData.userId}
//                     onChange={handleChange}
//                   />
//                   {errors.userId && <div className="invalid-feedback">{errors.userId}</div>}
//                 </div>

//                 {/* Mobile */}
//                 <div className="mb-3">
//                   <label htmlFor="mobile" className="form-label">Mobile Number*</label>
//                   <div className="input-group">
//                     <span className="input-group-text">+91</span>
//                     <input
//                       type="text"
//                       className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
//                       id="mobile"
//                       name="mobile"
//                       placeholder="Enter mobile number"
//                       value={formData.mobile}
//                       onChange={handleChange}
//                     />
//                   </div>
//                   {errors.mobile && <div className="invalid-feedback">{errors.mobile}</div>}
//                 </div>

//                 {/* Email */}
//                 <div className="mb-3">
//                   <label htmlFor="email" className="form-label">Email*</label>
//                   <input
//                     type="email"
//                     className={`form-control ${errors.email ? 'is-invalid' : ''}`}
//                     id="email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                   />
//                   {errors.email && <div className="invalid-feedback">{errors.email}</div>}
//                 </div>

//                 {/* Profession */}
//                 <div className="mb-3">
//                   <label htmlFor="profession" className="form-label">Profession*</label>
//                   <select
//                     className={`form-select ${errors.profession ? 'is-invalid' : ''}`}
//                     id="profession"
//                     name="profession"
//                     value={formData.profession}
//                     onChange={handleChange}
//                   >
//                     <option value="">Select Profession</option>
//                     {professions.map((profession, index) => (
//                       <option key={index} value={profession}>
//                         {profession}
//                       </option>
//                     ))}
//                   </select>
//                   {errors.profession && <div className="invalid-feedback">{errors.profession}</div>}
//                 </div>

//                 {/* Submit Button */}
//                 <div className="d-grid">
//                   <button 
//                     type="submit" 
//                     className="btn btn-dark"
//                     disabled={isSubmitting}
//                   >
//                     {isSubmitting ? 'Signing Up...' : 'Sign Up'}
//                   </button>
//                 </div>

//                 {/* Login Link */}
//                 <div className="text-center mt-3">
//                   Already have an account? <a href="/login" className="text-decoration-none">Login</a>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignUp;



// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import CreatorWerse from "../../assets/CreatorWerse.png";
// import axios from 'axios';

// const SignUp = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     userId: '',
//     mobile: '',
//     email: '',
//     profession: ''
//   });

//   const [errors, setErrors] = useState({});
//   const [successMessage, setSuccessMessage] = useState('');
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const professions = [
//     'Software Developer',
//     'Doctor',
//     'Teacher',
//     'Engineer',
//     'Designer',
//     'Business Analyst',
//     'Other'
//   ];

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//     if (errors[name]) {
//       setErrors(prevErrors => ({
//         ...prevErrors,
//         [name]: ''
//       }));
//     }
//   };

//   const validateForm = () => {
//     const newErrors = {};

//     if (!formData.firstName.trim()) {
//       newErrors.firstName = 'First name is required';
//     }

//     if (!formData.lastName.trim()) {
//       newErrors.lastName = 'Last name is required';
//     }

//     if (!formData.userId.trim()) {
//       newErrors.userId = 'User ID is required';
//     } else if (formData.userId.length < 4) {
//       newErrors.userId = 'User ID must be at least 4 characters';
//     }

//     if (!formData.mobile) {
//       newErrors.mobile = 'Mobile number is required';
//     }

//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!formData.email) {
//       newErrors.email = 'Email is required';
//     } else if (!emailRegex.test(formData.email)) {
//       newErrors.email = 'Please enter a valid email address';
//     }

//     if (!formData.profession) {
//       newErrors.profession = 'Profession is required';
//     }

//     return newErrors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationErrors = validateForm();

//     if (Object.keys(validationErrors).length === 0) {
//       try {
//         setIsSubmitting(true);
//         const data = new FormData();
//         data.append('first_name', formData.firstName);
//         data.append('last_name', formData.lastName);
//         data.append('userid', formData.userId);
//         data.append('mobile', formData.mobile);
//         data.append('country_code', '+91');
//         data.append('email', formData.email);
//         data.append('profession', '1');
//         data.append('action', 'signup');

//         const config = {
//           method: 'post',
//           maxBodyLength: Infinity,
//           url: 'http://creatorwerse.pmpframe.com/api/signup.php',
//           headers: { 
//             'Content-Type': 'multipart/form-data'
//           },
//           data: data
//         };

//         const response = await axios.request(config);
//         console.log('API Response:', response.data);

//         if (response.data.success) {
//           setFormData({
//             firstName: '',
//             lastName: '',
//             userId: '',
//             mobile: '',
//             email: '',
//             profession: ''
//           });
          
//           setSuccessMessage('Registered successfully!');
          
//           setTimeout(() => {
//             navigate('/login');
//           }, 2000);
//         } else {
//           setErrors({
//             api: response.data.message || 'Signup failed. Please try again.'
//           });
//         }
//       } catch (error) {
//         console.error('API Error:', error);
//         setErrors({
//           api: 'An error occurred during signup. Please try again later.'
//         });
//       } finally {
//         setIsSubmitting(false);
//       }
//     } else {
//       setErrors(validationErrors);
//     }
//   };

//   return (
//     <div style={{ 
//       backgroundColor: 'black', 
//       minHeight: '100vh',
//       width: '100%',
//       margin: 0,
//       padding: 0,
//       overflow: 'auto',
//       position: 'fixed',
//       top: 0,
//       left: 0
//     }}>
//       <div className="row justify-content-center">
//         <div className="col-md-6">
//           <div className="card" style={{ backgroundColor: 'black',  }}>
//             <div className="card-body">
//               <div 
//                 className="text-center mb-4 py-4" 
//                 style={{ 
//                   backgroundColor: 'black',
//                   width: '100%',
//                   height: '200px',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   borderRadius: '8px'
//                 }}
//               >
//                 <img 
//                   src={CreatorWerse} 
//                   alt="Logo" 
//                   className="mb-3" 
//                   style={{ 
//                     width: '150px',
//                     height: '100px',
//                     objectFit: 'contain'
//                   }} 
//                 />
//                 <h2 className="text-white">SignUp</h2>
//               </div>

//               <form onSubmit={handleSubmit}>
//                 {errors.api && (
//                   <div className="alert alert-danger" role="alert">
//                     {errors.api}
//                   </div>
//                 )}

//                 {successMessage && (
//                   <div className="alert alert-success" role="alert">
//                     {successMessage}
//                   </div>
//                 )}

//                 <div className="mb-3">
//                   <label htmlFor="firstName" className="form-label text-white">First Name</label>
//                   <input
//                     type="text"
//                     className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
//                     id="firstName"
//                     name="firstName"
//                     value={formData.firstName}
//                     onChange={handleChange}
//                     style={{ 
//                       backgroundColor: 'black',
//                       color: 'white',
//                       border: '1px solid white'
//                     }}
//                   />
//                   {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
//                 </div>

//                 <div className="mb-3">
//                   <label htmlFor="lastName" className="form-label text-white">Last Name</label>
//                   <input
//                     type="text"
//                     className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
//                     id="lastName"
//                     name="lastName"
//                     value={formData.lastName}
//                     onChange={handleChange}
//                     style={{ 
//                       backgroundColor: 'black',
//                       color: 'white',
//                       border: '1px solid white'
//                     }}
//                   />
//                   {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
//                 </div>

//                 <div className="mb-3">
//                   <label htmlFor="userId" className="form-label text-white">User ID</label>
//                   <input
//                     type="text"
//                     className={`form-control ${errors.userId ? 'is-invalid' : ''}`}
//                     id="userId"
//                     name="userId"
//                     value={formData.userId}
//                     onChange={handleChange}
//                     style={{ 
//                       backgroundColor: 'black',
//                       color: 'white',
//                       border: '1px solid white'
//                     }}
//                   />
//                   {errors.userId && <div className="invalid-feedback">{errors.userId}</div>}
//                 </div>

//                 <div className="mb-3">
//                   <label htmlFor="mobile" className="form-label text-white">Mobile Number</label>
//                   <div className="input-group">
//                     <span className="input-group-text" style={{ backgroundColor: 'black', color: 'white', border: '1px solid white' }}>+91</span>
//                     <input
//                       type="text"
//                       className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
//                       id="mobile"
//                       name="mobile"
//                       placeholder="Enter mobile number"
//                       value={formData.mobile}
//                       onChange={handleChange}
//                       style={{ 
//                         backgroundColor: 'black',
//                         color: 'white',
//                         border: '1px solid white'
//                       }}
//                     />
//                   </div>
//                   {errors.mobile && <div className="invalid-feedback">{errors.mobile}</div>}
//                 </div>

//                 <div className="mb-3">
//                   <label htmlFor="email" className="form-label text-white">Email*</label>
//                   <input
//                     type="email"
//                     className={`form-control ${errors.email ? 'is-invalid' : ''}`}
//                     id="email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     style={{ 
//                       backgroundColor: 'black',
//                       color: 'white',
//                       border: '1px solid white'
//                     }}
//                   />
//                   {errors.email && <div className="invalid-feedback">{errors.email}</div>}
//                 </div>

//                 <div className="mb-3">
//                   <label htmlFor="profession" className="form-label text-white">Profession</label>
//                   <select
//                     className={`form-select ${errors.profession ? 'is-invalid' : ''}`}
//                     id="profession"
//                     name="profession"
//                     value={formData.profession}
//                     onChange={handleChange}
//                     style={{ 
//                       backgroundColor: 'black',
//                       color: 'white',
//                       border: '1px solid white'
//                     }}
//                   >
//                     <option value="" style={{ backgroundColor: 'black', color: 'white' }}>Select Profession</option>
//                     {professions.map((profession, index) => (
//                       <option 
//                         key={index} 
//                         value={profession}
//                         style={{ backgroundColor: 'black', color: 'white' }}
//                       >
//                         {profession}
//                       </option>
//                     ))}
//                   </select>
//                   {errors.profession && <div className="invalid-feedback">{errors.profession}</div>}
//                 </div>

//                 {/* <div className="d-grid">
//                   <button 
//                     type="submit" 
//                     className="btn"
//                     style={{
//                       backgroundColor: '#800080',
//                       color: 'white',
//                       border: '1px solid #800080',
//                       padding: '10px',
//                       transition: 'all 0.3s ease',
//                     }}
//                     onMouseOver={(e) => {
//                       e.target.style.backgroundColor = '#660066';
//                       e.target.style.borderColor = '#660066';
//                     }}
//                     onMouseOut={(e) => {
//                       e.target.style.backgroundColor = '#800080';
//                       e.target.style.borderColor = '#800080';
//                     }}
//                     disabled={isSubmitting}
//                   >
//                     {isSubmitting ? 'Signing Up...' : 'Sign Up'}
//                   </button>
//                 </div> */}

//                   <div className="d-grid">
//                   <button 
//                     type="submit" 
//                     className="btn"
//                     style={{
//                       background: 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))',
//                       color: 'white',
//                       border: '1px solid rgba(0, 255, 128, 0.3)',
//                       padding: '12px',
//                       transition: 'all 0.4s ease',
//                       boxShadow: '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)',
//                       fontWeight: '600',
//                       letterSpacing: '0.5px',
//                       position: 'relative',
//                       overflow: 'hidden',
//                       borderRadius: '6px',
//                       fontSize: '16px',
//                     }}
//                     onMouseOver={(e) => {
//                       e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.95), rgba(0, 200, 100, 0.95))';
//                       e.target.style.borderColor = 'rgba(0, 255, 128, 0.8)';
//                       e.target.style.boxShadow = '0 6px 25px rgba(0, 170, 85, 0.4), 0 0 30px rgba(0, 255, 128, 0.4)';
//                       e.target.style.transform = 'translateY(-2px) scale(1.01)';
//                       e.target.style.letterSpacing = '1px';
//                     }}
//                     onMouseOut={(e) => {
//                       e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))';
//                       e.target.style.borderColor = 'rgba(0, 255, 128, 0.3)';
//                       e.target.style.boxShadow = '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)';
//                       e.target.style.transform = 'translateY(0) scale(1)';
//                       e.target.style.letterSpacing = '0.5px';
//                     }}
//                     disabled={isSubmitting}
//                   >
//                     <span style={{ 
//                       position: 'relative',
//                       zIndex: 1,
//                       textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
//                       display: 'inline-block'
//                     }}>
//                       {isSubmitting ? 'Signing Up...' : 'Sign Up'}
//                     </span>
//                   </button>
//                 </div>
//                 <div className="text-center mt-3">
//                   <span className="text-white">Already have an account? </span>
//                   <a href="/login" className="text-white text-decoration-none" style={{ borderBottom: '1px solid white' }}>Login</a>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignUp;




// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import CreatorWerse from "../../assets/CreatorWerse.png";
// import axios from 'axios';

// const SignUp = () => {
//   const navigate = useNavigate();
//   const countries = [
//     { dial_code: "+1", code: "US" },    // United States
//     { dial_code: "+7", code: "RU" },    // Russia
//     { dial_code: "+27", code: "ZA" },   // South Africa
//     { dial_code: "+31", code: "NL" },   // Netherlands
//     { dial_code: "+32", code: "BE" },   // Belgium
//     { dial_code: "+33", code: "FR" },   // France
//     { dial_code: "+34", code: "ES" },   // Spain
//     { dial_code: "+39", code: "IT" },   // Italy
//     { dial_code: "+41", code: "CH" },   // Switzerland
//     { dial_code: "+43", code: "AT" },   // Austria
//     { dial_code: "+44", code: "GB" },   // United Kingdom
//     { dial_code: "+46", code: "SE" },   // Sweden
//     { dial_code: "+49", code: "DE" },   // Germany
//     { dial_code: "+52", code: "MX" },   // Mexico
//     { dial_code: "+54", code: "AR" },   // Argentina
//     { dial_code: "+55", code: "BR" },   // Brazil
//     { dial_code: "+60", code: "MY" },   // Malaysia
//     { dial_code: "+61", code: "AU" },   // Australia
//     { dial_code: "+62", code: "ID" },   // Indonesia
//     { dial_code: "+63", code: "PH" },   // Philippines
//     { dial_code: "+64", code: "NZ" },   // New Zealand
//     { dial_code: "+65", code: "SG" },   // Singapore
//     { dial_code: "+66", code: "TH" },   // Thailand
//     { dial_code: "+81", code: "JP" },   // Japan
//     { dial_code: "+82", code: "KR" },   // South Korea
//     { dial_code: "+84", code: "VN" },   // Vietnam
//     { dial_code: "+86", code: "CN" },   // China
//     { dial_code: "+90", code: "TR" },   // Turkey
//     { dial_code: "+91", code: "IN" },   // India
//     { dial_code: "+92", code: "PK" },   // Pakistan
//     { dial_code: "+93", code: "AF" },   // Afghanistan
//     { dial_code: "+213", code: "DZ" },  // Algeria
//     { dial_code: "+355", code: "AL" },  // Albania
//     { dial_code: "+880", code: "BD" },  // Bangladesh
//     { dial_code: "+966", code: "SA" },  // Saudi Arabia
//     { dial_code: "+971", code: "AE" },  // UAE
//   ];

//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     mobile: '',
//     email: '',
//     profession: '',
//     countryCode: '+91' // Default country code
//   });

//   const [errors, setErrors] = useState({});
//   const [successMessage, setSuccessMessage] = useState('');
//   const [isSubmitting, setIsSubmitting] = useState(false);



//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//     if (errors[name]) {
//       setErrors(prevErrors => ({
//         ...prevErrors,
//         [name]: ''
//       }));
//     }
//   };

//   const validateForm = () => {
//     const newErrors = {};

//     // if (!formData.firstName.trim()) {
//     //   newErrors.firstName = 'First name is required';
//     // }

//     // if (!formData.lastName.trim()) {
//     //   newErrors.lastName = 'Last name is required';
//     // }

//     // if (!formData.mobile) {
//     //   newErrors.mobile = 'Mobile number is required';
//     // }

//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!formData.email) {
//       newErrors.email = 'Email is required';
//     } else if (!emailRegex.test(formData.email)) {
//       newErrors.email = 'Please enter a valid email address';
//     }

//     // if (!formData.profession) {
//     //   newErrors.profession = 'Profession is required';
//     // }

//     return newErrors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const validationErrors = validateForm();

//     if (Object.keys(validationErrors).length === 0) {
//       try {
//         setIsSubmitting(true);
//         const data = new FormData();
//         data.append('first_name', formData.firstName);
//         data.append('last_name', formData.lastName);
//         data.append('mobile', formData.mobile);
//         data.append('country_code', formData.countryCode);
//         data.append('email', formData.email);
//         data.append('profession', formData.profession);
//         data.append('action', 'signup');

//         const config = {
//           method: 'post',
//           maxBodyLength: Infinity,
//           url: 'https://creatorwerse.pmpframe.com/api/signup.php',
//           headers: { 
//             'Content-Type': 'multipart/form-data'
//           },
//           data: data
//         };

//         const response = await axios.request(config);
//         console.log('API Response:', response.data);

//         if (response.data.success) {
//           setFormData({
//             firstName: '',
//             lastName: '',
//             mobile: '',
//             email: '',
//             profession: '',
//             countryCode: '+91'
//           });
          
//           setSuccessMessage('Registered successfully!');
          
//           setTimeout(() => {
//             navigate('/login');
//           }, 2000);
//         } else {
//           setErrors({
//             api: response.data.message || 'Signup failed. Please try again.'
//           });
//         }
//       } catch (error) {
//         console.error('API Error:', error);
//         setErrors({
//           api: 'An error occurred during signup. Please try again later.'
//         });
//       } finally {
//         setIsSubmitting(false);
//       }
//     } else {
//       setErrors(validationErrors);
//     }
//   };

//   return (
//     <div style={{ 
//       backgroundColor: 'black', 
//       minHeight: '100vh',
//       width: '100%',
//       margin: 0,
//       padding: 0,
//       overflow: 'auto',
//       position: 'fixed',
//       top: 0,
//       left: 0
//     }}>
//       <div className="row justify-content-center">
//         <div className="col-md-6">
//           <div className="card" style={{ backgroundColor: 'black' }}>
//             <div className="card-body">
//               <div 
//                 className="text-center mb-4 py-4" 
//                 style={{ 
//                   backgroundColor: 'black',
//                   width: '100%',
//                   height: '200px',
//                   display: 'flex',
//                   flexDirection: 'column',
//                   justifyContent: 'center',
//                   alignItems: 'center',
//                   borderRadius: '8px'
//                 }}
//               >
//                 <img 
//                   src={CreatorWerse} 
//                   alt="Logo" 
//                   className="mb-3" 
//                   style={{ 
//                     width: '150px',
//                     height: '100px',
//                     objectFit: 'contain'
//                   }} 
//                 />
//                 <h2 className="text-white">SignUp</h2>
//               </div>

//               <form onSubmit={handleSubmit}>
//                 {errors.api && (
//                   <div className="alert alert-danger" role="alert">
//                     {errors.api}
//                   </div>
//                 )}

//                 {successMessage && (
//                   <div className="alert alert-success" role="alert">
//                     {successMessage}
//                   </div>
//                 )}

//                 <div className="mb-3">
//                   <label htmlFor="firstName" className="form-label text-white">First Name</label>
//                   <input
//                     type="text"
//                     className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
//                     id="firstName"
//                     name="firstName"
//                     value={formData.firstName}
//                     onChange={handleChange}
//                     style={{ 
//                       backgroundColor: 'black',
//                       color: 'white',
//                       border: '1px solid white'
//                     }}
//                   />
//                   {errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
//                 </div>

//                 <div className="mb-3">
//                   <label htmlFor="lastName" className="form-label text-white">Last Name</label>
//                   <input
//                     type="text"
//                     className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
//                     id="lastName"
//                     name="lastName"
//                     value={formData.lastName}
//                     onChange={handleChange}
//                     style={{ 
//                       backgroundColor: 'black',
//                       color: 'white',
//                       border: '1px solid white'
//                     }}
//                   />
//                   {errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
//                 </div>

//                 <div className="mb-3">
//                   <label htmlFor="mobile" className="form-label text-white">Mobile Number</label>
//                   <div className="input-group">
//                     <select
//                       className="input-group-text"
//                       style={{
//                         backgroundColor: 'white',
//                         color: 'black',
//                         border: '1px solid white',
//                         minWidth: '90px',
//                         cursor: 'pointer',
//                         fontWeight: '500',
//                         fontSize: '14px'
//                       }}
//                       name="countryCode"
//                       value={formData.countryCode}
//                       onChange={handleChange}
//                     >
//                       {countries.map((country) => (
//                         <option 
//                           key={country.code} 
//                           value={country.dial_code}
//                           style={{ 
//                             backgroundColor: 'white', 
//                             color: 'black',
//                             padding: '8px'
//                           }}
//                         >
//                           {country.dial_code}
//                         </option>
//                       ))}
//                     </select>
//                     <input
//                       type="text"
//                       className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
//                       id="mobile"
//                       name="mobile"
//                       placeholder="Enter mobile number"
//                       value={formData.mobile}
//                       onChange={handleChange}
//                       style={{ 
//                         backgroundColor: 'black',
//                         color: 'white',
//                         border: '1px solid white'
//                       }}
//                     />
//                   </div>
//                   {errors.mobile && <div className="invalid-feedback">{errors.mobile}</div>}
//                 </div>

//                 <div className="mb-3">
//                   <label htmlFor="email" className="form-label text-white">Email*</label>
//                   <input
//                     type="email"
//                     className={`form-control ${errors.email ? 'is-invalid' : ''}`}
//                     id="email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     style={{ 
//                       backgroundColor: 'black',
//                       color: 'white',
//                       border: '1px solid white'
//                     }}
//                   />
//                   {errors.email && <div className="invalid-feedback">{errors.email}</div>}
//                 </div>

//                 <div className="mb-3">
//                   <label htmlFor="profession" className="form-label text-white">Profession</label>
//                   <input
//                     type="text"
//                     className={`form-control ${errors.profession ? 'is-invalid' : ''}`}
//                     id="profession"
//                     name="profession"
//                     value={formData.profession}
//                     onChange={handleChange}
//                     placeholder="Enter your profession"
//                     style={{ 
//                       backgroundColor: 'black',
//                       color: 'white',
//                       border: '1px solid white'
//                     }}
//                   />
//                   {errors.profession && <div className="invalid-feedback">{errors.profession}</div>}
//                 </div>

//                 <div className="d-grid">
//                   <button 
//                     type="submit" 
//                     className="btn"
//                     style={{
//                       background: 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))',
//                       color: 'white',
//                       border: '1px solid rgba(0, 255, 128, 0.3)',
//                       padding: '12px',
//                       transition: 'all 0.4s ease',
//                       boxShadow: '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)',
//                       fontWeight: '600',
//                       letterSpacing: '0.5px',
//                       position: 'relative',
//                       overflow: 'hidden',
//                       borderRadius: '6px',
//                       fontSize: '16px',
//                     }}
//                     onMouseOver={(e) => {
//                       e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.95), rgba(0, 200, 100, 0.95))';
//                       e.target.style.borderColor = 'rgba(0, 255, 128, 0.8)';
//                       e.target.style.boxShadow = '0 6px 25px rgba(0, 170, 85, 0.4), 0 0 30px rgba(0, 255, 128, 0.4)';
//                       e.target.style.transform = 'translateY(-2px) scale(1.01)';
//                       e.target.style.letterSpacing = '1px';
//                     }}
//                     onMouseOut={(e) => {
//                       e.target.style.background = 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))';
//                       e.target.style.borderColor = 'rgba(0, 255, 128, 0.3)';
//                       e.target.style.boxShadow = '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)';
//                       e.target.style.transform = 'translateY(0) scale(1)';
//                       e.target.style.letterSpacing = '0.5px';
//                     }}
//                     disabled={isSubmitting}
//                   >
//                     <span style={{ 
//                       position: 'relative',
//                       zIndex: 1,
//                       textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
//                       display: 'inline-block'
//                     }}>
//                       {isSubmitting ? 'Signing Up...' : 'Sign Up'}
//                     </span>
//                   </button>
//                 </div>
//                 <div className="text-center mt-3">
//                   <span className="text-white">Already have an account? </span>
//                   <a href="/login" className="text-white text-decoration-none" style={{ borderBottom: '1px solid white' }}>Login</a>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SignUp;




// import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate, Link } from 'react-router-dom';
// import CreatorWerse from "../../assets/CreatorWerse.png";
// import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import CreatorWerse from "../../assets/CreatorWerse.png";
import axios from 'axios';

const SignUp = () => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const alertTimeoutRef = useRef(null);
  
  const countries = [
    { dial_code: "+1", code: "US", name: "United States" },
    { dial_code: "+7", code: "RU", name: "Russia" },
    { dial_code: "+27", code: "ZA", name: "South Africa" },
    { dial_code: "+31", code: "NL", name: "Netherlands" },
    { dial_code: "+32", code: "BE", name: "Belgium" },
    { dial_code: "+33", code: "FR", name: "France" },
    { dial_code: "+34", code: "ES", name: "Spain" },
    { dial_code: "+39", code: "IT", name: "Italy" },
    { dial_code: "+41", code: "CH", name: "Switzerland" },
    { dial_code: "+43", code: "AT", name: "Austria" },
    { dial_code: "+44", code: "GB", name: "United Kingdom" },
    { dial_code: "+46", code: "SE", name: "Sweden" },
    { dial_code: "+49", code: "DE", name: "Germany" },
    { dial_code: "+52", code: "MX", name: "Mexico" },
    { dial_code: "+54", code: "AR", name: "Argentina" },
    { dial_code: "+55", code: "BR", name: "Brazil" },
    { dial_code: "+60", code: "MY", name: "Malaysia" },
    { dial_code: "+61", code: "AU", name: "Australia" },
    { dial_code: "+62", code: "ID", name: "Indonesia" },
    { dial_code: "+63", code: "PH", name: "Philippines" },
    { dial_code: "+64", code: "NZ", name: "New Zealand" },
    { dial_code: "+65", code: "SG", name: "Singapore" },
    { dial_code: "+66", code: "TH", name: "Thailand" },
    { dial_code: "+81", code: "JP", name: "Japan" },
    { dial_code: "+82", code: "KR", name: "South Korea" },
    { dial_code: "+84", code: "VN", name: "Vietnam" },
    { dial_code: "+86", code: "CN", name: "China" },
    { dial_code: "+90", code: "TR", name: "Turkey" },
    { dial_code: "+91", code: "IN", name: "India" },
    { dial_code: "+92", code: "PK", name: "Pakistan" },
    { dial_code: "+93", code: "AF", name: "Afghanistan" },
    { dial_code: "+213", code: "DZ", name: "Algeria" },
    { dial_code: "+355", code: "AL", name: "Albania" },
    { dial_code: "+880", code: "BD", name: "Bangladesh" },
    { dial_code: "+966", code: "SA", name: "Saudi Arabia" },
    { dial_code: "+971", code: "AE", name: "United Arab Emirates" },
  ];

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    profession: '',
    countryCode: '+91'
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(countries.find(c => c.dial_code === '+91'));



//alert messages
  useEffect(() => {
    return () => {
      if (alertTimeoutRef.current) {
        clearTimeout(alertTimeoutRef.current);
      }
    };
  }, []);


  const clearAlerts = () => {
    if (alertTimeoutRef.current) {
      clearTimeout(alertTimeoutRef.current);
    }
    alertTimeoutRef.current = setTimeout(() => {
      setErrors({});
      setSuccessMessage('');
    }, 5000);
  };




  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const filteredCountries = countries.filter(country => 
    country.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    country.dial_code.includes(searchQuery)
  );

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setFormData(prev => ({ ...prev, countryCode: country.dial_code }));
    setShowDropdown(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length === 0) {
      try {
        setIsSubmitting(true);
        setErrors({}); // Clear any existing errors
        setSuccessMessage(''); // Clear any existing success message

        const data = new FormData();
        data.append('first_name', formData.firstName);
        data.append('last_name', formData.lastName);
        data.append('mobile', formData.mobile);
        data.append('country_code', formData.countryCode);
        data.append('email', formData.email);
        data.append('profession', formData.profession);
        data.append('action', 'signup');

        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: 'https://creatorwerse.pmpframe.com/api/signup.php',
          headers: { 
            'Content-Type': 'multipart/form-data'
          },
          data: data
        };

        const response = await axios.request(config);
        if (response.data.success) {
          setFormData({
            firstName: '',
            lastName: '',
            mobile: '',
            email: '',
            profession: '',
            countryCode: '+91'
          });
          setSuccessMessage('Registered successfully!');
          clearAlerts(); // Set timeout to clear the success message
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        } else {
          setErrors({
            api: response.data.message || 'Signup failed. Please try again.'
          });
          clearAlerts(); // Set timeout to clear the error message
        }
      } catch (error) {
        console.error('API Error:', error);
        setErrors({
          api: 'An error occurred during signup. Please try again later.'
        });
        clearAlerts(); // Set timeout to clear the error message
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(validationErrors);
      clearAlerts(); // Set timeout to clear validation errors
    }
  };

  return (
    <div className="min-vh-100 bg-black w-100" style={{ overflowY: 'auto' }}>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6">
            <div className="card bg-black">
              <div className="card-body p-4">
                <div className="text-center mb-4">
                  {/* <img 
                    src={CreatorWerse} 
                    alt="Logo" 
                    className="mb-3" 
                    style={{ width: '150px', height: '100px', objectFit: 'contain' }}
                  /> */}
                    <Link to="/">
          <img 
          src={CreatorWerse} 
          alt="Logo" 
          className="mb-3" 
          style={{ width: '150px', height: '100px', objectFit: 'contain' }}
        />
      </Link>

                  <h2 className="text-white">SignUp</h2>
                </div>

                <form onSubmit={handleSubmit}>
                  {errors.api && (
                    <div className="alert alert-danger" role="alert">
                      {errors.api}
                    </div>
                  )}

                  {successMessage && (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  )}

                  <div className="mb-3">
                    <label className="form-label text-white">First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control bg-black text-white"
                      value={formData.firstName}
                      onChange={handleChange}
                      style={{ border: '1px solid white' }}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label text-white">Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      className="form-control bg-black text-white"
                      value={formData.lastName}
                      onChange={handleChange}
                      style={{ border: '1px solid white' }}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label text-white">Mobile Number</label>
                    <div className="input-group" ref={dropdownRef}>
                      <div className="dropdown">
                        <button
                          type="button"
                          className="btn btn-light dropdown-toggle"
                          onClick={() => setShowDropdown(!showDropdown)}
                          style={{ minWidth: '120px', textAlign: 'left' }}
                        >
                          {selectedCountry.dial_code}
                        </button>
                        {showDropdown && (
                          <div className="dropdown-menu show" style={{ maxHeight: '250px', overflowY: 'auto', width: '300px' }}>
                            <div className="px-3 py-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search countries..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onClick={(e) => e.stopPropagation()}
                              />
                            </div>
                            <div className="dropdown-divider"></div>
                            {filteredCountries.map((country) => (
                              <button
                                key={country.code}
                                className="dropdown-item d-flex justify-content-between align-items-center"
                                type="button"
                                onClick={() => handleCountrySelect(country)}
                              >
                                <span>{country.name}</span>
                                <span className="text-muted">{country.dial_code}</span>
                              </button>
                            ))}
                          </div>
                        )}
                      </div>
                      <input
                        type="text"
                        name="mobile"
                        className="form-control bg-black text-white"
                        value={formData.mobile}
                        onChange={handleChange}
                        placeholder="Enter mobile number"
                        style={{ border: '1px solid white' }}
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label className="form-label text-white">Email*</label>
                    <input
                      type="email"
                      name="email"
                      className={`form-control bg-black text-white ${errors.email ? 'is-invalid' : ''}`}
                      value={formData.email}
                      onChange={handleChange}
                      style={{ border: '1px solid white' }}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label className="form-label text-white">Profession</label>
                    <input
                      type="text"
                      name="profession"
                      className="form-control bg-black text-white"
                      value={formData.profession}
                      onChange={handleChange}
                      placeholder="Enter your profession"
                      style={{ border: '1px solid white' }}
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn w-100 mb-3"
                    disabled={isSubmitting}
                    style={{
                      background: 'linear-gradient(135deg, rgba(128, 0, 128, 0.9), rgba(0, 170, 85, 0.9))',
                      color: 'white',
                      border: '1px solid rgba(0, 255, 128, 0.3)',
                      padding: '12px',
                      boxShadow: '0 4px 15px rgba(0, 170, 85, 0.3), 0 0 20px rgba(0, 255, 128, 0.2)',
                      fontWeight: '600',
                      letterSpacing: '0.5px'
                    }}
                  >
                    {isSubmitting ? 'Signing Up...' : 'Sign Up'}
                  </button>

                  <div className="text-center">
                    <span className="text-white">Already have an account? </span>
                    <a href="/login" className="text-white text-decoration-none" style={{ borderBottom: '1px solid white' }}>
                      Login
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;