import React from 'react';
import { ArrowRight, Play } from 'lucide-react';
import VandanaTestimonial from "../assets/VandanaTestimonial.jpg";

const LandingPage = () => {
  return (
    <div className="container-fluid bg-dark text-white py-5">
      <div className="container">
        {/* Hero Section */}
        <div className="text-center mb-5">
          <h1 className="display-2 fw-bolder mb-4">
            <span className="text-success border-bottom border-success border-3 pb-2">This is Not</span>
            <br className="d-none d-md-block" />
            <span className="gradient-title">Just a Live Event</span>
          </h1>
          
          <p className="fs-2 fw-light">
            It's your chance to be{" "}
            <span className="fw-bold animate-shine">The Authority</span>{" "}
            in life you truly{" "}
            <span className="fw-bolder text-success animate-pulse">DESERVE</span>
          </p>
        </div>

        {/* Challenges Section */}
        <div className="bg-black text-white rounded-4 shadow-lg p-5 mb-5">
          <h2 className="fs-2 fw-bold mb-4 gradient-text">But you CAN'T just because of:</h2>
          <ul className="list-unstyled">
            {[
              'your own self doubt of writing scripts',
              'your own fear of the camera, and',
              'your own perfectionism'
            ].map((item, i) => (
              <li key={i} className="d-flex align-items-center mb-4">
                <div className="bg-danger rounded-circle me-3 pulse-dot"></div>
                <span className="fs-5 hover-underline text-white">{item}</span>
              </li>
            ))}
          </ul>
        </div>

        {/* Features Section */}
        <div className="text-center mb-5">
          <h3 className="display-5 fw-bold mb-4">
            This LIVE EVENTs at{" "}
            <span className="fw-bolder gradient-text">CREATORWERSE</span> have 
            <span className="text-success animate-shine"> THE POTENTIAL</span> to eliminate all the
            <span className="fw-bolder text-warning"> HESITATION</span> within you
          </h3>
          
          <div className="fs-3">
            <p className="mb-4">
              And{" "}
              <span className="highlight-text">BREAKTHROUGH</span> your{" "}
              <span className="highlight-text">CREATIVE BLOCKS</span> in such a way,
            </p>
            
            <p>
              That you don't have to struggle with content creation on{" "}
              <span className="text-success fw-bolder animate-bounce">YOUR OWN</span>
            </p>
          </div>
        </div>

        {/* Instead Section with Testimonial */}
        <div className="row align-items-center mb-5 g-5">
          <div className="col-lg-6">
            <h2 className="display-3 fw-bolder gradient-text mb-4">
              INSTEAD...
            </h2>
            <div className="hover-lift">
              <p className="fs-3 fw-light mb-4 letter-spacing">
                Our Team WILL WORK with YOU & together we can{" "}
                <span className="fw-bold text-success">BUILD</span> A sustainable content strategy 
                that can keep your audience{" "}
                <span className="text-warning fw-bold">engaged</span>.
              </p>
              <p className="fst-italic text-light fs-5 border-start border-success border-4 ps-4 mb-4">
                Relying on destiny doesn't work anymore as the world is moving faster.
              </p>
              <div>
                <p className="fs-4 mb-3">
                  If you're <span className="text-warning fw-bold animate-pulse">STUCK</span>{" "}
                  doesn't mean the opportunities will wait for you...
                </p>
                <h3 className="display-4 fw-bolder text-danger mb-3 animate-shake">
                  IT WON'T!
                </h3>
                <p className="fs-4">
                  So your <span className="highlight-text">MINDSET</span>{" "}
                  demands a shift, not the <span className="fw-bolder">SITUATION!</span>
                </p>
              </div>
            </div>
          </div>

          {/* Testimonial Card */}
          <div className="col-lg-6">
            <div className="testimonial-wrapper">
              <div className="card border-0 shadow-xl hover-lift overflow-hidden">
                <div className="position-relative">
                  <div className="image-container">
                    <img 
                      src={VandanaTestimonial}
                      alt="Vandana Testimonial" 
                      className="w-100 h-100"
                      style={{ 
                        objectFit: 'cover', 
                        objectPosition: 'center',
                        height: '750px'
                      }}
                    />
                    <div className="gradient-overlay"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CTA Section */}
        <div className="text-center">
          <h2 className="display-4 fw-bolder gradient-text mb-4">HERE IT IS...</h2>
          <p className="fs-3 fw-light mb-5 mx-auto" style={{maxWidth: '800px'}}>
            Take <span className="text-success fw-bold animate-pulse">ACTION</span> & move{" "}
            <span className="text-warning fw-bold">100 STEPS AHEAD</span> to crush your 
            content creation struggles & experience organic social media{" "}
            <span className="text-success fw-bolder animate-shine">SUCCESS</span>.
          </p>
          <button className="btn btn-success btn-lg px-5 py-4 rounded-pill mb-4 fs-4 d-inline-flex 
                           align-items-center gap-3 cta-button">
            <span className="fw-bold">Click Here To Book Your Seat Now!</span>
            <ArrowRight size={24} className="animate-arrow" />
          </button>
        </div>
      </div>

      <style jsx>{`
        /* Essential Animations */
        @keyframes shine {
          0% {
            background-position: -100% center;
          }
          100% {
            background-position: 200% center;
          }
        }

        .animate-shine {
          background: linear-gradient(
            90deg,
            transparent 20%,
            rgba(255, 255, 255, 0.4) 50%,
            transparent 80%
          );
          background-size: 200% 100%;
          animation: shine 3s linear infinite;
          background-clip: text;
          -webkit-background-clip: text;
          color: #28a745;
        }

        .animate-pulse {
          animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }

        @keyframes pulse {
          0%, 100% {
            opacity: 1;
            transform: scale(1);
          }
          50% {
            opacity: 0.8;
            transform: scale(1.05);
          }
        }

        .animate-bounce {
          animation: bounce 2s infinite;
        }

        @keyframes bounce {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-15px);
          }
        }

        .animate-shake {
          animation: shake 4s cubic-bezier(.36,.07,.19,.97) infinite;
        }

        @keyframes shake {
          0%, 100% { transform: translateX(0); }
          10%, 30%, 50%, 70%, 90% { transform: translateX(-10px); }
          20%, 40%, 60%, 80% { transform: translateX(10px); }
        }

        /* Gradient Styles */
        .gradient-text {
          background: linear-gradient(135deg, #28a745, #ffc107);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          color: transparent;
        }

        .gradient-title {
          background: linear-gradient(135deg, #ffc107, #28a745);
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          color: transparent;
        }

        /* Hover Effects */
        .hover-lift {
          transition: transform 0.3s ease-out;
        }

        .hover-lift:hover {
          transform: translateY(-8px);
        }

        .play-button {
          transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        }

        .play-button:hover {
          transform: scale(1.15);
          box-shadow: 0 0 30px rgba(40, 167, 69, 0.6);
        }

        .highlight-text {
          position: relative;
          color: #28a745;
          font-weight: bold;
          transition: color 0.3s ease;
        }

        .highlight-text::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -4px;
          width: 100%;
          height: 2px;
          background-color: #28a745;
          transform: scaleX(0);
          transition: transform 0.3s ease;
          transform-origin: right;
        }

        .highlight-text:hover::after {
          transform: scaleX(1);
          transform-origin: left;
        }

        /* Testimonial Card Styles */
        .testimonial-wrapper {
          height: 100%;
        }

        .image-container {
          position: relative;
          width: 100%;
          height: 100%; 
          overflow: hidden;
        }

        .gradient-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(
            to bottom,
            rgba(0,0,0,0.2),
            rgba(0,0,0,0.5)
          );
        }

        .shadow-xl {
          box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.2),
                     0 10px 10px -5px rgba(0, 0, 0, 0.1);
        }

        /* CTA Button Animation */
        .animate-arrow {
          animation: moveRight 1.5s infinite;
        }

        @keyframes moveRight {
          0%, 100% {
            transform: translateX(0);
          }
          50% {
            transform: translateX(5px);
          }
        }

        .cta-button {
          transition: all 0.3s ease;
          box-shadow: 0 4px 14px rgba(40, 167, 69, 0.3);
        }

        .cta-button:hover {
          transform: translateY(-3px);
          box-shadow: 0 6px 20px rgba(40, 167, 69, 0.4);
        }

        .hover-underline {
          position: relative;
          display: inline-block;
        }

        .hover-underline::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: -4px;
          left: 0;
          background-color: #dc3545;
          transform: scaleX(0);
          transition: transform 0.3s ease;
        }

        .hover-underline:hover::after {
          transform: scaleX(1);
        }

        .pulse-dot {
          width: 12px;
          height: 12px;
          animation: pulse-dot 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
        }

        @keyframes pulse-dot {
          0% {
            transform: scale(0.8);
          }
          50% {
            transform: scale(1.2);
          }
          100% {
            transform: scale(0.8);
          }
        }
      `}</style>
    </div>
  );
};

export default LandingPage;