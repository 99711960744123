// // RoughPage.jsx
// import React from 'react';

// const RoughPage = ({ formData }) => {
//   return (
//     <div style={{ backgroundColor: 'black', minHeight: '100vh', color: 'white', padding: '2rem' }}>
//       <div className="container">
//         <h1 className="text-center mb-4">Hello! Form Submitted Successfully</h1>
//         <div className="card bg-dark text-white">
//           <div className="card-body">
//             <h3>Submitted Information:</h3>
//             <ul className="list-unstyled">
//               <li className="mb-2"><strong>Product/Service:</strong> {formData.product}</li>
//               <li className="mb-2"><strong>Target Audience:</strong> {formData.targetAudience}</li>
//               <li className="mb-2"><strong>Results:</strong> {formData.results}</li>
//               <li className="mb-2"><strong>Business Name:</strong> {formData.businessName}</li>
//               <li className="mb-2"><strong>Your Name:</strong> {formData.name}</li>
//               <li className="mb-2"><strong>Need Approval:</strong> {formData.needApproval ? 'Yes' : 'No'}</li>
//             </ul>
//           </div>
//         </div>
//         <div className="text-center mt-4">
//           <button 
//             className="btn btn-light"
//             onClick={() => window.history.back()}
//           >
//             Go Back
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RoughPage;



// RoughPage.jsx
// import React from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';

// const RoughPage = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const formData = location.state || {
//     product: '',
//     targetAudience: '',
//     results: '',
//     businessName: '',
//     name: '',
//     needApproval: false
//   };

//   const styles = {
//     input: {
//       backgroundColor: 'black',
//       color: 'white',
//       border: '1px solid #555',
//       fontSize: '18px',
//       padding: '12px'
//     },
//     label: {
//       color: 'white',
//       fontSize: '16px'
//     },
//     card: {
//       backgroundColor: 'black',
//       border: 'none',
//       width: '80%',
//       marginLeft: 'auto',
//       marginRight: 'auto'
//     },
//     cardTitle: {
//       color: 'white',
//       fontSize: '24px'
//     },
//     logoutButton: {
//       position: 'fixed',
//       top: '20px',
//       right: '20px',
//       zIndex: 1000,
//       padding: '8px 20px',
//       fontSize: '16px',
//       fontWeight: '600',
//       cursor: 'pointer',
     
//     }
//   };



//   const handleLogout = () => {
//     navigate('/');
//   };

//   return (
//     <div style={{ backgroundColor: 'black', minHeight: '100vh', color: 'white', padding: '2rem' }}>
//        <button
        

//         className="btn btn-outline-warning btn-lg"
//         style={styles.logoutButton}
//         onClick={handleLogout}
       
//       >
//         Logout
//       </button>
//       <div className="container">
//         <h1 className="text-center mb-4">Hello! Form Submitted Successfully</h1>
//         <div className="card bg-dark text-white">
//           <div className="card-body">
//             <h3>Submitted Information:</h3>
//             <ul className="list-unstyled">
//               <li className="mb-2"><strong>Product/Service:</strong> {formData.product}</li>
//               <li className="mb-2"><strong>Target Audience:</strong> {formData.targetAudience}</li>
//               <li className="mb-2"><strong>Results:</strong> {formData.results}</li>
//               <li className="mb-2"><strong>Business Name:</strong> {formData.businessName}</li>
//               <li className="mb-2"><strong>Your Name:</strong> {formData.name}</li>
//               <li className="mb-2"><strong>Need Approval:</strong> {formData.needApproval ? 'Yes' : 'No'}</li>
//             </ul>
//           </div>
//         </div>
//         <div className="text-center mt-4">
//           <button 
//             className="btn btn-light"
//             onClick={() => navigate('/reelscriptform')}
//           >
//             Go Back
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RoughPage;

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RoughPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const formData = location.state || {
    product: '',
    targetAudience: '',
    results: '',
    businessName: '',
    name: '',
    needApproval: false
  };

  const styles = {
    input: {
      backgroundColor: 'black',
      color: 'white',
      border: '1px solid #555',
      fontSize: '18px',
      padding: '12px'
    },
    label: {
      color: 'white',
      fontSize: '16px'
    },
    card: {
      backgroundColor: 'black',
      border: 'none',
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    cardTitle: {
      color: 'white',
      fontSize: '24px'
    },
    logoutButton: {
      position: 'fixed',
      top: '20px',
      right: '20px',
      zIndex: 1000,
      padding: '8px 20px',
      fontSize: '16px',
      fontWeight: '600',
      cursor: 'pointer',
    }
  };

  const handleLogout = () => {
    navigate('/');
  };

  return (
    <div className="bg-black min-vh-100 text-white p-3 p-sm-4 p-md-5">
      {/* Responsive Logout Button */}
      <div className="position-fixed top-0 end-0 p-3 p-sm-4">
        <button
          className="btn btn-outline-warning btn-lg d-none d-sm-inline-block"
          onClick={handleLogout}
        >
          Logout
        </button>
        {/* Smaller button for mobile */}
        <button
          className="btn btn-outline-warning d-inline-block d-sm-none"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>

      <div className="container px-3 px-sm-4">
        <div className="pt-5 pt-sm-2">
          <h1 className="text-center mb-4 fs-3 fs-sm-2 fs-md-1">
            Hello! Form Submitted Successfully
          </h1>
        
          <div className="card bg-dark text-white mx-auto" style={{ maxWidth: '800px' }}>
            <div className="card-body p-3 p-sm-4">
              <h3 className="fs-4 fs-md-3 mb-4">Submitted Information:</h3>
              <ul className="list-unstyled">
                <li className="mb-3">
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <strong>Product/Service:</strong>
                    </div>
                    <div className="col-12 col-sm-8">
                      {formData.product}
                    </div>
                  </div>
                </li>
                <li className="mb-3">
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <strong>Target Audience:</strong>
                    </div>
                    <div className="col-12 col-sm-8">
                      {formData.targetAudience}
                    </div>
                  </div>
                </li>
                <li className="mb-3">
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <strong>Results:</strong>
                    </div>
                    <div className="col-12 col-sm-8">
                      {formData.results}
                    </div>
                  </div>
                </li>
                <li className="mb-3">
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <strong>Business Name:</strong>
                    </div>
                    <div className="col-12 col-sm-8">
                      {formData.businessName}
                    </div>
                  </div>
                </li>
                <li className="mb-3">
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <strong>Your Name:</strong>
                    </div>
                    <div className="col-12 col-sm-8">
                      {formData.name}
                    </div>
                  </div>
                </li>
                <li className="mb-3">
                  <div className="row">
                    <div className="col-12 col-sm-4">
                      <strong>Need Approval:</strong>
                    </div>
                    <div className="col-12 col-sm-8">
                      {formData.needApproval ? 'Yes' : 'No'}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="text-center mt-4">
            <button
              className="btn btn-light btn-lg"
              onClick={() => navigate('/reelscriptform')}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoughPage;