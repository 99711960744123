import React from 'react';
import testimonial1 from '../assets/testimonial1.jpg';
import testimonial2 from '../assets/testimonial2.jpg';
import testimonial3 from '../assets/testimonial3.jpg';
import testimonial4 from '../assets/testimonial4.jpg';
import testimonial5 from '../assets/testimonial5.jpg';
import testimonial6 from '../assets/testimonial6.jpg';

const TestimonialSection = () => {
  // Array of testimonial data with imported images
  const testimonials = [
    { id: 1, image: testimonial1, alt: "Mamta Testimonial" },
    { id: 2, image: testimonial2, alt: "Preeti Testimonial" },
    { id: 3, image: testimonial3, alt: "Harpreet Testimonial" },
    { id: 4, image: testimonial5, alt: "Suresh Testimonial" },
    { id: 5, image: testimonial4, alt: "Meena Testimonial" },
    { id: 6, image: testimonial6, alt: "Dhriti Testimonial" }
  ];

  return (
    <section className="bg-dark text-white py-5">
      <div className="container">
        {/* Top Button */}
        <div className="text-center mb-5">
          <button className="btn btn-success btn-lg fw-semibold rounded-pill px-5 py-3">
            Click Here To Book Your Seat Now!
          </button>
        </div>

        {/* Main Title */}
        <div className="text-center mb-5">
          <h1 className="display-5 fw-bold mb-3">
            Is it possible to Create 100 Videos just BY ATTENDING THIS{' '}
            <span className="text-primary">3-days LIVE EVENT</span>?
          </h1>
          <p className="lead text-secondary">
            Let's hear from the ones who have already gone through this powerful transformation.
          </p>
        </div>

        {/* First Row */}
        <div className="row g-4 mb-4">
          {testimonials.slice(0, 3).map(testimonial => (
            <div key={testimonial.id} className="col-12 col-md-4">
              <div className="position-relative">
                <img
                  src={testimonial.image}
                  alt={testimonial.alt}
                  className="img-fluid rounded shadow"
                  style={{ width: '100%', cursor: 'pointer' }}
                />
              </div>
            </div>
          ))}
        </div>

        {/* Second Row */}
        <div className="row g-4 mb-5">
          {testimonials.slice(3, 6).map(testimonial => (
            <div key={testimonial.id} className="col-12 col-md-4">
              <div className="position-relative">
                <img
                  src={testimonial.image}
                  alt={testimonial.alt}
                  className="img-fluid rounded shadow"
                  style={{ width: '100%', cursor: 'pointer' }}
                />
              </div>
            </div>
          ))}
        </div>

        {/* Bottom Button */}
        <div className="text-center">
          <button className="btn btn-success btn-lg fw-semibold rounded-pill px-5 py-3">
            Click Here To Book Your Seat Now!
          </button>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
